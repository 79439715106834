<template>
  <b-form-input ref="inputRef" type="text" :size="size" placeholder="Budget" />
</template>

<script>
import { useCurrencyInput } from "vue-currency-input";

export default {
  name: "CurrencyInput",
  props: {
    value: {
      type: Number,
      default: 0,
    },
    size: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { inputRef } = useCurrencyInput({ currency: "EUR" });

    return { inputRef };
  },
};
</script>
