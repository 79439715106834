<template>
  <div>
    <div style="margin-top: -56px">
      <div class="col-10">
        <p class="h2">Consulter initiative</p>
      </div>
      <hr />
    </div>
    <b-form class="d-flex" style="gap: 5px">
      <div class="col-md-6 col-12 shdow">
        <div class="col-12 p-0 d-flex flex-wrap justify-content-between">
          <p class="h4 text-secondary my-auto mr-auto">Informations générales</p>
        </div>
        <div class="col-12 p-0">
          <hr class="bg-secondary mb-3 mt-1" />
        </div>
        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Ref : </label>
          </div>
          <div class="col-md-8 font-weight-bold">INIT-{{ initiative.id }}</div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Libellé : </label>
          </div>
          <div class="col-md-8 font-weight-bold">{{ initiative.libelle }}</div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Responsable : </label>
          </div>
          <div v-if="initiative.responsable" class="col-md-8 font-weight-bold">
            {{ initiative.responsable.firstname }}
            {{ initiative.responsable.lastname }}
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Statut : </label>
          </div>
          <div class="col-md-8 font-weight-bold">
            <label>{{ initiative.status }}</label>
          </div>
        </div>
        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Type objet : </label>
          </div>
          <div v-if="initiative.type" class="col-md-8 font-weight-bold">
            {{ initiative.type }}
          </div>
          <div v-else class="col-md-8 font-weight-bold">-</div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Objet principale : </label>
          </div>
          <div class="col-md-8 font-weight-bold">CRO Salesforce</div>
        </div>
        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Phase projet : </label>
          </div>
          <div class="col-md-8 font-weight-bold">
            {{ initiative.phases.length ? initiative.phases[0].libelle : "-" }}
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12 shdow">
        <div class="col-12 p-0 d-flex flex-wrap justify-content-between">
          <p class="h4 text-secondary my-auto mr-auto">Avancement</p>
        </div>
        <div class="col-12 p-0">
          <hr class="bg-secondary mb-3 mt-1" />
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Échéances : </label>
          </div>
          <div class="col-md-8 font-weight-bold">
            <span
              class="text-white text-center px-2 py-1 rounded"
              :style="{ backgroundColor: dateBackgroundColor(initiative.endingAt) }"
              >{{ moment(initiative.endingAt).format("DD/MM/YYYY") }}</span
            >
          </div>
        </div>
        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label>Avancement : </label>
          </div>
          <div class="col-md-8 font-weight-bold">
            <b-progress max="100" show-value height="1rem">
              <b-progress-bar
                :value="initiative.avancement || 0"
                :label="`${initiative.avancement || 0}%`"
                :style="`background-color: ${
                  initiative.avancement > 20 && initiative.avancement <= 70
                    ? '#f5f245'
                    : initiative.avancement > 70
                    ? '#23d92c'
                    : '#f5ba45'
                }`"
              ></b-progress-bar>
            </b-progress>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>
<script>
// import TableResponsable from "@/components/custom/TableResponsable";
import moment from "moment";
export default {
  // components: { TableResponsable },
  props: {
    initiative: {
      type: Object,
    },
  },
  methods: {
    dateBackgroundColor: function (date) {
      // "#38b000" : "#d20f35";
      moment(date);
      var diff = parseInt(
        moment
          .duration(moment(date, "DD/MM/YYYY").diff(moment(new Date(), "DD/MM/YYYY")))
          .asDays()
      );
      return diff < 0 ? "#d20f35" : diff > 4 ? "#38b000" : "#ffd60a";
    },
  },
};
</script>
