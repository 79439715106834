<template>
  <div class="container-fluid rounded bg-white py-4">
    <div class="d-flex">
      <span class="h2 mr-auto text-secondary"> <!-- Nouvelle FdR--></span>

      <div class="row mb-3">
        <div class="col-12">
          <modal-actions @close="$router.go(-1)" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12"></div>
    </div>

    <div id="add-page" class="p-0 m-0 mb-3">
      <div class="col-12 p-0">
        <div>
          <div class="row">
            <div class="col-12 d-flex flex-wrap justify-content-between">
              <span class="h2 mr-auto text-secondary">Nouvelle FdR</span>
              <div>
                <b-button
                  type="submit"
                  @click="onSubmit"
                  pill
                  :disabled="
                    submitingForm ||
                    !(
                      this.isAdmin ||
                      this.isSuper ||
                      this.isManager ||
                      this.isPo
                    )
                  "
                >
                  <b-spinner
                    small
                    v-if="submitingForm"
                    class="text-white"
                    label="Loading..."
                  ></b-spinner>
                  Confirmer</b-button
                >
              </div>
            </div>
          </div>

          <!-- <hr class="mt-1 mb-3 bg-secondary" /> -->
          <div class="d-flex mt-3" style="gap: 5px">
            <!-- INFORMATION -->
            <div class="shdow col-md-4 col-12">
              <div>
                <span class="h4 text-secondary">Informations générales</span>
              </div>
              <hr class="mt-1 mb-3 bg-secondary" />

              <b-form-group
                class="font-weight-bold"
                label="Libellé:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <b-form-input
                  v-model="newFdr.name"
                  type="text"
                  placeholder="Libellé"
                  :class="{
                    'is-invalid':
                      $v.newFdr.name.$error && $v.newFdr.name.$dirty,
                  }"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newFdr.name.$dirty"
                >
                  {{
                    !$v.newFdr.name.required
                      ? "Champ obligatoire"
                      : !$v.newFdr.name.minLength
                      ? `Le champ doit contenir au moins ${$v.newFdr.name.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>

              <b-form-group
                class="font-weight-bold"
                label="Entité:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newFdr.entite.$error && $v.newFdr.entite.$dirty,
                  }"
                >
                  <v-select
                    class="bg-transparent"
                    :options="entityList"
                    label="text"
                    placeholder="Entité"
                    v-model="newFdr.entite"
                    :reduce="(entite) => entite.value"
                    @input="newFdr.organisation = null"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newFdr.entite.$dirty"
                >
                  {{ !$v.newFdr.entite.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>
              <b-form-group
                class="font-weight-bold"
                label="Organisation:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newFdr.organisation.$error &&
                      $v.newFdr.organisation.$dirty,
                  }"
                >
                  <v-select
                    class="bg-transparent"
                    label="text"
                    placeholder="Organisation"
                    v-model="newFdr.organisation"
                    :options="organisations"
                    :reduce="(organisation) => organisation.value"
                    :class="{
                      'is-invalid':
                        $v.newFdr.organisation.$error &&
                        $v.newFdr.organisation.$dirty,
                    }"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newFdr.organisation.$dirty"
                >
                  {{
                    !$v.newFdr.organisation.required ? "Champ obligatoire" : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>

              <b-form-group
                class="font-weight-bold"
                label="Budget:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <CurrencyInput
                  placeholder="Budget"
                  v-model="newFdr.budget"
                  :value="newFdr.budget"
                  class="font-weight-normal"
                  :class="{
                    'is-invalid':
                      $v.newFdr.budget.$error && $v.newFdr.budget.$dirty,
                  }"
                />
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newFdr.budget.$dirty"
                >
                  {{
                    !$v.newFdr.budget.required
                      ? "Champ obligatoire"
                      : !$v.newFdr.budget.minValue
                      ? `Le budget doit étre positif.`
                      : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>

              <b-form-group
                class="font-weight-bold"
                label="Date de création:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <date-picker
                  :value="new Date()"
                  class="col-12 p-0 font-weight-normal"
                  format="DD/MM/YYYY"
                  disabled
                />
              </b-form-group>

              <b-form-group
                class="font-weight-bold"
                label="Patrimoine:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                >
                  <!-- :class="{
                    'is-invalid':
                      $v.newFdr.patrimoine.$error &&
                      $v.newFdr.patrimoine.$dirty,
                  }" -->
                  <v-select
                    class="bg-transparent"
                    :options="patrimoineList"
                    label="text"
                    placeholder="Patrimoine"
                    v-model="newFdr.patrimoine"
                    :disabled="$route.params.fromPatrimoine ? true : false"
                    :reduce="(patrimoine) => patrimoine.value"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <!-- <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newFdr.patrimoine.$dirty"
                >
                  {{
                    !$v.newFdr.patrimoine.required ? "champ obligatoire" : ""
                  }}
                </span> -->
                <!--  -->
              </b-form-group>

              <b-form-group
                class="font-weight-bold"
                label="Responsable finance:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newFdr.finance.responsable.$error &&
                      $v.newFdr.finance.responsable.$dirty,
                  }"
                >
                  <v-select
                    class="bg-transparent"
                    placeholder="Responsable finance"
                    label="text"
                    :options="responsables"
                    :reduce="(responsable) => responsable.value"
                    v-model="newFdr.finance.responsable"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newFdr.finance.responsable.$dirty"
                >
                  {{
                    !$v.newFdr.finance.responsable.required
                      ? "Champ obligatoire"
                      : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>

              <b-form-group
                class="font-weight-bold"
                label="Responsable:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newFdr.responsable.$error &&
                      $v.newFdr.responsable.$dirty,
                  }"
                >
                  <v-select
                    class="bg-transparent"
                    placeholder="Responsable"
                    v-model="newFdr.responsable"
                    label="text"
                    :options="responsables"
                    :reduce="(responsable) => responsable.value"
                    required
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newFdr.responsable.$dirty"
                >
                  {{
                    !$v.newFdr.responsable.required ? "Champ obligatoire" : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>

              <b-form-group
                class="font-weight-bold"
                label="Type FDR:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newFdr.type.$error && $v.newFdr.type.$dirty,
                  }"
                >
                  <v-select
                    class="bg-transparent"
                    :options="fdrTypes"
                    type="text"
                    placeholder="Type FdR"
                    v-model="newFdr.type"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newFdr.type.$dirty"
                >
                  {{ !$v.newFdr.type.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>

              <b-form-group
                class="font-weight-bold"
                label="Statut:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <div
                  class="font-weight-normal p-0 form-control"
                  style="padding: 0 !important; height: fit-content"
                  :class="{
                    'is-invalid':
                      $v.newFdr.status.$error && $v.newFdr.status.$dirty,
                  }"
                >
                  <v-select
                    class="bg-transparent"
                    v-model="newFdr.status"
                    placeholder="Statut"
                    :options="statusFdr"
                    :class="{
                      'is-invalid':
                        $v.newFdr.status.$error && $v.newFdr.status.$dirty,
                    }"
                  >
                    <template #no-options> Liste vide </template>
                  </v-select>
                </div>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newFdr.status.$dirty"
                >
                  {{ !$v.newFdr.status.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>
            </div>
            <!-- PROGRESS -->
            <div class="shdow col-md-4 col-12">
              <div>
                <span class="h4 text-secondary">Avancement</span>
              </div>
              <hr class="mt-1 mb-3 bg-secondary" />

              <b-form-group
                class="font-weight-bold"
                label="Date début:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <date-picker
                  :value="new Date()"
                  class="col-12 p-0 font-weight-normal"
                  v-model="newFdr.startedAt"
                  placeholder="Date début"
                  format="DD/MM/YYYY"
                  :input-attr="{
                    class: {
                      'is-invalid':
                        $v.newFdr.startedAt.$error &&
                        $v.newFdr.startedAt.$dirty,
                      ' form-control': true,
                    },
                  }"
                />
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newFdr.startedAt.$dirty"
                >
                  {{ !$v.newFdr.startedAt.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </b-form-group>

              <b-form-group
                class="font-weight-bold"
                label="Date fin:"
                label-cols-sm="4"
                label-align-sm="left"
              >
                <!-- <date-picker /> -->
                <date-picker
                  :value="new Date()"
                  class="col-12 p-0 font-weight-normal"
                  v-model="newFdr.endingAt"
                  placeholder="Date fin"
                  :input-attr="{
                    class: {
                      'is-invalid':
                        $v.newFdr.endingAt.$error && $v.newFdr.endingAt.$dirty,
                      ' form-control': true,
                    },
                  }"
                  format="DD/MM/YYYY"
                />
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newFdr.endingAt.$dirty"
                >
                  {{
                    !$v.newFdr.endingAt.required
                      ? "Champ obligatoire"
                      : $v.newFdr.endingAt.required && $v.newFdr.endingAt.$error
                      ? "La date de fin ne doit pas être antérieur à la date de début"
                      : ""
                  }}
                </span>
                <!--  -->
              </b-form-group>
            </div>
            <!-- COUVERTURES -->
            <div class="shdow col-md-4 col-12">
              <div>
                <span class="h4 text-secondary">Couvertures</span>
              </div>
              <hr class="mt-1 mb-3 bg-secondary" />
              <Places :testCouverture="sendingFunction" />
              <div class="row m-0 p-0">
                <div
                  style="height: 50px; position: relative; overflow: visible"
                  class="shdow-2 m-1"
                  v-for="(covert, index) in couvertures"
                  :key="index"
                >
                  <p>
                    <country-flag
                      :country="covert.countryCode"
                      size="small"
                      v-show="covert.countryCode"
                    />{{ covert.country }}
                  </p>

                  <div style="position: absolute; top: -5px; right: -5px">
                    <span style="cursor: pointer"
                      ><i
                        @click="deleteCouverture(index)"
                        class="mdi mdi-close-circle text-danger"
                      ></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr />

    <b-tabs class="tab-solid tab-solid-primary mt-5">
      <b-tab
        v-if="isAdmin || isSuper || isPo"
        title="Finance"
        active
        lazy
        class="my-2"
      >
        <div class="row justify-content-between p-0 m-0 flex-wrap">
          <!-- <div class="col-sm-6 col-12 mb-2 pl-sm-0"> -->
          <div class="p-0 col-12 mb-2">
            <div class="m-0 p-0 h-100 shdow">
              <div class="hdr py-2 text-white rounded mb-3">
                Situation actuelle : {{ moment().format("DD/MM/YYYY") }}
              </div>
              <div class="p-4">
                <div class="d-flex">
                  <p class="mt-2"><span class="mr-1"> Fichier : </span></p>
                  <FilePicker
                    disabled
                    class="font-weight-normal my-auto"
                    :isfinance="true"
                  />
                </div>
                <div @submit.prevent="addAjustement">
                  <div class="row m-0 p-0 align-items-center">
                    <span class="mr-2 col-3 p-0">Ajustement</span>

                    <b-form-input
                      placeholder="Ajuster"
                      class="col-5 mr-2 border border-gray"
                      size="sm"
                      type="number"
                      required
                    ></b-form-input>
                  </div>
                  <div class="row m-0 mt-2 p-0 align-items-center">
                    <span class="mr-2 col-3 p-0">Commentaire</span>
                    <b-form-input
                      placeholder="Commentaire"
                      class="col-5 mr-2 border border-gray"
                      size="sm"
                      required
                    ></b-form-input>
                  </div>
                  <div class="row m-0 mt-2 p-0 align-items-center">
                    <div class="d-flex p-0 col-8">
                      <b-button
                        disabled
                        size="sm"
                        type="submit"
                        class="bg-blue ml-auto"
                        >Réviser</b-button
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-if="false"
                class="text-gray d-flex h2 text-center w-100"
                style="height: 166px"
              >
                <i
                  class="mdi my-auto ml-auto mdi-information-outline my-auto"
                ></i>
                <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
              </div>
            </div>
          </div>
          <div v-if="false" class="col-sm-6 col-12 mb-2 pr-sm-0">
            <div class="m-0 p-0 h-100 shdow">
              <div class="hdr py-2 text-white rounded mb-3">Suivi conso:</div>
              <div
                class="text-gray d-flex h2 text-center w-100"
                style="height: 166px"
              >
                <i
                  class="mdi my-auto ml-auto mdi-information-outline my-auto"
                ></i>
                <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
              </div>
            </div>
          </div>
        </div>

        <div class="hdr py-2 text-white rounded mt-4 mb-3">
          Historique des révisions
        </div>

        <RevisionHistorique :budget="'0'" :finance="null" />
      </b-tab>
      <b-tab title="Enjeux stratégiques">
        <StrategicChallenge
          :disabled="true"
          :strategicChallenge="[]"
          @cancelItem="(itemIndex) => removeStrategyChallengeInputs(itemIndex)"
        />
      </b-tab>

      <b-tab title="Initiatives">
        <Initiatives :disabled="true" :initiatives="[]" />
      </b-tab>

      <b-tab title="Plan de transformation">
        <div
          class="text-gray d-flex h2 text-center w-100"
          style="height: 166px"
        >
          <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
          <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
        </div>
      </b-tab>

      <b-tab title="Comité et décision">
        <CommitteeDecision
          :disabled="true"
          :tableItems="[]"
          @searchGuests="searchGuestsModal = !searchGuestsModal"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import CountryFlag from "vue-country-flag";
import FilePicker from "@/components/custom/FilePicker.vue";
import StrategicChallenge from "./components/StrategicChallenge.vue";
import Initiatives from "./components/Initiatives.vue";
import CommitteeDecision from "./components/CommitteeDecision.vue";
import ModalActions from "@/components/custom/ModalActions.vue";
import Swal from "sweetalert2";
import moment from "moment";
import { mapGetters } from "vuex";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import RevisionHistorique from "./components/RevisionHistorique";
import Places from "@/components/custom/Places.vue";
import CurrencyInput from "@/components/custom/CurrencyInput";

export default {
  name: "AddFdr",
  components: {
    FilePicker,
    RevisionHistorique,
    StrategicChallenge,
    Initiatives,
    CommitteeDecision,
    ModalActions,
    Places,
    CountryFlag,
    CurrencyInput,
  },
  data: () => ({
    currentDate: moment().format("DD/MM/YYYY"),
    submitingForm: false,
    newFdr: {
      endingAt: "",
      startedAt: "",
      status: "",
      type: "",
      responsable: "",
      finance: {
        responsable: "",
      },
      patrimoine: "",
      organisation: "",
      entite: "",
      name: "",
      budget: 0,
    },
    text: "",
    transformationInitiativeOptions: [{ value: null, text: "Toutes" }],
    transformationApplicationOptions: [{ value: null, text: "Applications" }],
    selectedTransformationInitiative: { text: "Toutes", value: null },
    selectedTransformationApplication: { text: "Application", value: null },
    searchGuestsModal: false,
    showAddInitiative: false,
    financeHistory: {
      tableFields: [
        {
          key: "createdAt",
          label: "Date révision",
          sortable: true,
        },
        // { key: "responsable", label: "Resp Fin", sortable: true },
        { key: "commentaire", label: "Commentaire", sortable: true },
        { key: "budget", label: "Budget initiative", sortable: true },
        { key: "montant", label: "Montant ajusté", sortable: true },
        {
          key: "rest",
          label: "Budget initiative révisé",
          sortable: true,
        },
        { key: "tendance", label: "Tendance", sortable: true },
      ],
    },
    couvertures: [],
    responsableFin: null,
  }),
  validations: {
    newFdr: {
      endingAt: {
        required,
        // minValue: minValue(this.newFdr.startedAt),
        minValue(val) {
          return this.newFdr.startedAt
            ? moment(val, "DD/MM/YYYY").diff(
                moment(this.newFdr.startedAt, "DD/MM/YYYY")
              ) +
                1 >
                0
            : true;
        },
      },
      startedAt: {
        required,
      },
      status: {
        required,
      },
      type: {
        required,
      },
      responsable: {
        required,
      },
      finance: {
        responsable: {
          required,
        },
      },
      // patrimoine: {
      //   required,
      // },
      organisation: {
        required,
      },
      entite: {
        required,
      },
      budget: {
        required,
        minValue(val) {
          return parseFloat(val) > 0;
        },
      },
      name: {
        required,
        minLength: minLength(4),
      },
    },
  },
  methods: {
    clone(data) {
      var loc = new Array();
      loc.country = data.country;
      loc.countryCode = data.countryCode;
      loc.lat = data.lat;
      loc.lng = data.lng;
      loc.adresse = data.adresse;
      return loc;
    },
    sendingFunction: function (location) {
      (!this.couvertures.find(
        (couverture) => couverture.countryCode === location.countryCode
      ) &&
        this.couvertures.push(this.clone(location))) ||
        Swal.fire(
          "Impossible d'ajouter une couverture déjà existante !",
          "",
          "error"
        );
    },
    deleteCouverture(index) {
      this.couvertures.splice(index, 1);
    },
    onSubmit: function () {
      if (this.isAdmin || this.isSuper || this.isManager || this.isPo) {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          var item = {
            ...this.newFdr,
            description: "",
            patrimoine: this.newFdr.patrimoine
              ? `/api/referentiel/patrimoines/${this.newFdr.patrimoine}`
              : null,
            endingAt: moment(this.newFdr.endingAt, "DD/MM/YYYY").format(
              "DD/MM/YYYY"
            ),
            startedAt: moment(this.newFdr.startedAt, "DD/MM/YYYY").format(
              "DD/MM/YYYY"
            ),
            budget: parseFloat(this.newFdr.budget),
          };
          delete item.finance;
          this.submitingForm = true;
          this.responsableFin = this.newFdr.finance.responsable;
          this.$store
            .dispatch("fdr/createFdr", item)
            .then(() => {
              this.submitingForm = false;
            })
            .then(() => {
              this.$store.dispatch("financeFeuille/updateFinanceFeuille", {
                id: this.FDR.finance.id,
                responsable: this.responsableFin,
              });
            });
        }
      }
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("cStatus/fetchAllStatusByType", "Feuille");
    this.$store.dispatch("cType/fetchFeuilleTypes");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines").then(() => {
      if (this.$route.params.patrimoineId) {
        this.newFdr.patrimoine = parseInt(this.$route.params.patrimoineId);
      }
    });
    this.$store.dispatch("entity/fetchAllEntities");
  },
  mounted() {
    // if (this.$route.params.patrimoineId) {
    //   this.newFdr.patrimoine = this.$route.params.patrimoineId;
    // }
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("cStatus", ["STATUS_FEUILLES"]), //get all status
    ...mapGetters("cType", ["FEUILLE_TYPES"]),
    ...mapGetters("patrimoine", ["PATRIMOINES", "PATRIMOINE"]),
    ...mapGetters("entity", ["ENTITIES"]),
    ...mapGetters("fdr", ["FDR"]),

    ...mapGetters([
      "AuthenticatedUser",
      "isAdmin",
      "isManager",
      "isSuper",
      "isPo",
    ]),

    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/users/${responsable.id}`,
        text: `${responsable.firstname} ${responsable.lastname}`,
      }));
    },
    statusFdr() {
      return this.STATUS_FEUILLES;
    },
    organisations() {
      return this.newFdr.entite
        ? this.isAdmin || this.isSuper
          ? this.ENTITIES.find(
              (entite) => entite["@id"] === this.newFdr.entite
            ).organisations.map((organisation) => ({
              value: `/api/referentiel/organisations/${organisation.id}`,
              text: organisation.libelle,
            }))
          : this.AuthenticatedUser &&
            this.AuthenticatedUser.organisationRatachement
          ? [this.AuthenticatedUser.organisationRatachement].map(
              (organisation) => ({
                value: `/api/referentiel/organisations/${organisation.id}`,
                text: organisation.libelle,
              })
            )
          : []
        : [];
    },
    fdrTypes() {
      return this.FEUILLE_TYPES;
    },
    patrimoineList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        value: patrimoine.id,
        text: patrimoine.name,
      }));
    },
    entityList() {
      return this.ENTITIES.filter(
        ({ organisations }) => organisations && organisations.length
      ).map((entite) => ({
        value: entite["@id"],
        text: entite.libelle,
      }));
    },
  },
  watch: {
    FDR: function () {
      this.couvertures.forEach((element) => {
        this.$store.dispatch("couverture/createCouverture", {
          ...element,
          feuilleRoute: `/api/transformation/feuille_routes/${this.FDR.id}`,
        });
      });
      this.$store.dispatch("financeFeuille/updateFinanceFeuille", {
        id: this.FDR.finance.id,
        responsable: this.responsableFin,
      });

      Swal.fire({
        title: "La FdR est bien créée !",
        type: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "show-fdr",
            params: {
              slug: this.FDR.slug || "abcd",
              id: this.FDR.id,
              fromCreation: true,
            },
          }); //redirect to the new task
        }
      });
    },
  },
};
</script>

<style scoped>
.txt_desc {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
.table_responsivo {
  overflow: scroll;
}

.popUpFather {
  position: relative;
}

@media (min-width: 991px) {
  .modal-xyz-548 {
    position: absolute;
    width: calc(100% - 331px) !important;
    height: 72% !important;
    top: 165px !important;
    right: 35px !important;
    /* transform: translateY(-50%) !important; */
    z-index: 3000;
    display: -webkit-box;
    display: -ms-flexbox;
    /* display: flex; */
    -webkit-box-align: center;
    -ms-flex-align: center;
    /* align-items: center; */
    /* -webkit-box-pack: center; */
    -ms-flex-pack: center;
    /* justify-content: center; */
  }
}

@media (max-width: 991px) {
  .modal-xyz-548 {
    width: 100%;
  }
}

.sub-modal {
  background-color: white;
  padding: 20px 30px;
  /* border-radius: 10px; */
  /* min-width: 950px;
  max-width: 950px;
  max-height: 80%; */
  width: 100%;
  height: 100%;
  position: relative;
}

.sb_mdl_ctrls {
  /* position: absolute; */
  /* top: -45px; */
  /* background-color: rebeccapurple; */
  height: 40px;
  /* right: 0px; */
  /* width: 100px; */
  display: flex;
  justify-content: end;
  align-items: center;
  margin-bottom: 10px;
}

.mdl_btn {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}

.tr_clckble {
  cursor: pointer;
}

.tr_clckble:hover {
  background-color: rgba(139, 44, 235, 0.342);
}

.hdr {
  background-color: #072648;
  text-align: center;
}

.hdr > p {
  color: white;
}

.spn {
  color: #797979;
}
</style>

<style>
.vs__search {
  color: #cfcfcf;
}
.mx-datepicker {
  width: 100% !important;
  height: 44.22px !important;
}
.vs__dropdown-toggle {
  height: 44.22px;
}
.shdow-2 {
  box-shadow: 1px 2px 6px -2px;
  padding: 20px;
  border-radius: 10px;
}
</style>
