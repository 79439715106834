<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-1 my-auto">Liste des initiatives </span>

      <DropDownCustom
        :items="['Tableau', 'Kanban', 'Gantt']"
        @changeValue="onValueChange"
        class="my-auto"
      />
      <div class="my-auto mx-0 ml-1" v-if="selected == 2">
        <b-dropdown
          id="dropdown-1"
          :text="selectedView.text"
          :class="`custom-drop m-0 btn-outline-info ${selectedView.color} `"
        >
          <b-dropdown-item
            class="col-sm-6 col-md-auto m-0 p-0"
            v-for="(view, index) in ganttView"
            :key="index"
          >
            <button
              @click="changeView(view, index)"
              type="button"
              :class="`btn ${view.color} btn-fw col-sm-12`"
            >
              {{ view.text }}
            </button>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div
        class="d-flex ml-auto my-auto justify-content-end"
        v-if="
          (isAdmin || isSuper || isPo) &&
          !$router.currentRoute.path.includes('print')
        "
      >
        <CustomAddButton
          text="Nouvelle initiative"
          @click="
            $router.push({
              name: 'add-initiative',
              params: {
                fromFdr: true,
              },
            })
          "
          :disabled="disabled"
        />
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>

    <div v-if="selected == 0">
      <b-table
        responsive
        :items="tableItems"
        :fields="tableFields"
        :per-page="$route.path.includes('print') ? tableItems.length : perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :busy="loading"
        show-empty
        bordered
        striped
      >
        <!-- FOR LOADING ANIMATIONS -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Chargement...</strong>
          </div>
        </template>
        <!-- SHOW IF TABLE IS EMPTY -->
        <template #empty>
          <div class="text-center text-gray">Table vide</div>
        </template>
        <template v-slot:cell(responsable)="data">
          <span v-if="!data.item.hasOwnProperty('editMode')"
            ><TableResponsable
              v-if="!$route.path.includes('print')"
              :responsable="data.value"
            />
            <span v-else>
              {{ data.value.firstname + " " + data.value.lastname }}
            </span>
          </span>

          <b-form-select
            v-else
            :options="responsables"
            v-model="selectedUser"
            placeholder="contributeur"
          />
        </template>
        <template v-slot:cell(avancement)="data">
          <b-progress
            max="100"
            show-progress
            height="15px"
            style="background-color: #cacccd !important"
          >
            <b-progress-bar
              :value="data.value || 0"
              :label="`${data.value || 0}%`"
              :style="`background-color: ${
                data.value > 20 && data.value <= 70
                  ? '#f5f245'
                  : data.value > 70
                  ? '#23d92c'
                  : '#f5ba45'
              }`"
            ></b-progress-bar>
          </b-progress>
        </template>

        <template v-slot:cell(endingAt)="data">
          <span
            v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
            v-b-tooltip.hover="
              echeanceStatus(
                data.item.createdAt,
                data.item.endingAt,
                data.item.status
              ).toUpperCase()
            "
            class="text-white text-center px-2 py-1 rounded"
            :style="{
              backgroundColor: dateBackgroundColor(
                data.item.createdAt,
                data.item.endingAt,
                data.item.status
              ),
            }"
            >{{
              moment(data.item.endingAt, "DD/MM/YYY").format("DD/MM/YYYY")
            }}</span
          >
          <date-picker
            v-else
            class="col-12 p-0 font-weight-normal"
            v-model="data.item.endingAt"
            placeholder="Date début"
            format="DD/MM/YYYY"
            :input-attr="{ required: 'true' }"
          />
        </template>

        <template v-slot:cell(ref)="data">
          <span style="color: blue">INIT-{{ data.item.id }}</span>
        </template>
        <template v-slot:cell(type)="data">
          <span v-if="data.value">{{ data.value }}</span>
          <span v-else class="d-block text-center">-</span>
        </template>

        <template v-slot:cell(phases)="data">
          <span
            v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
          >
            <span v-if="data.value.find((phase) => phase.isCurrent)"
              >{{ data.value.find((phase) => phase.isCurrent).libelle }}
            </span>
            <span v-else>-</span></span
          >
          <b-form-select
            v-else
            :options="phasesList"
            placeholder="Phase Initiative"
            v-model="selectedPhase"
          ></b-form-select>
        </template>
        <template v-slot:cell(libelle)="data">
          <span
            v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
          >
            <b-link
              class="mb-0 d-block"
              @click="showInitiativeFunction(data.item)"
              :to="{
                name: 'show-initiative',
                params: {
                  id: data.item.id,
                  slug: data.item.slug || 'abcde',
                },
              }"
              v-if="!$route.path.includes('print')"
              >{{ data.value }}</b-link
            >
            <span v-else>
              {{ data.value }}
            </span>
          </span>

          <input
            v-else
            type="text"
            class="form-control"
            v-model="data.item.libelle"
            placeholder="libelle"
          />
        </template>
        <template v-slot:cell(status)="data">
          <span
            v-if="!data.item.hasOwnProperty('editMode') || !data.item.editMode"
            >{{ data.value }}</span
          >

          <b-form-select
            v-else
            :options="INITIATIVE_STATUS"
            v-model="data.item.status"
          />
        </template>
        <template #cell(actions)="data">
          <table-actions
            :actions="actions()"
            :editMode="data.item.hasOwnProperty('editMode')"
            :transferMode="!data.item.hasOwnProperty('editMode')"
            @transferItem="
              (showTransferItem = !showTransferItem),
                (transformedInitiativeId = data.item.id),
                (selectedInitiative = data.item)
            "
            @deleteItem="deleteInitiative(data.item.id)"
            @showItem="showInitiativeFunction(data.item)"
            @editItem="editInitiative(data.item, data.item.id)"
            @confirmItem="confirmInitiative(data.item)"
            @cancelItem="cancelInitiative(data.item)"
          ></table-actions>
        </template>
      </b-table>
      <b-pagination
        v-if="!$route.path.includes('print')"
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="table-list"
        align="right"
      >
      </b-pagination>
    </div>

    <div v-if="selected == 1" class="board-wrapper pt-5">
      <initiativeKabnan
        v-if="$route.params.id"
        :items="tableItems"
        @transferItem="transform"
      />
      <div
        v-else
        class="text-gray d-flex h2 text-center w-100"
        style="height: 166px"
      >
        <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
        <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
      </div>
    </div>
    <div v-if="selected == 2" class="card p-3 mt-4">
      <Gantt
        v-if="$route.params.id"
        :items="tableItems"
        :loadingData="loading"
        :mode="mode"
      />
      <div
        v-else
        class="text-gray d-flex h2 text-center w-100"
        style="height: 166px"
      >
        <i class="mdi my-auto ml-auto mdi-information-outline my-auto"></i>
        <span class="my-auto ml-1 mr-auto"> Aucune donnée</span>
      </div>
    </div>
    <!-- Transfer initiative modal  -->
    <div v-show="showTransferItem" to="modals-xyz-548">
      <modal @close="showTransferItem = false">
        <transfer-initiative
          @transferInitiative="transferInitiative"
          :fdrs="FDRS"
          @close="showTransferItem = false"
          :initiative="selectedInitiative"
          :fdrName="fdrName"
        />
      </modal>
    </div>

    <!-- Add new initiative -->
    <div style="z-index: 1031" to="modals-xyz-548" v-if="showAddInitiative">
      <modal action="confirm" @close="showAddInitiative = !showAddInitiative">
        <add-new-initiative
          :fdrName="fdrName"
          @close="showAddInitiative = !showAddInitiative"
        />
      </modal>
    </div>

    <!-- Add new initiative -->
    <div to="modals-xyz-548" v-if="showInitiative">
      <modal @close="showInitiative = !showInitiative">
        <show-initiative :initiative="selectedInitiative" />
      </modal>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import TransferInitiative from "./TransferInitiative.vue";
import Modal from "@/components/custom/Modal.vue";
import AddNewInitiative from "./AddNewInitiative.vue";
import ShowInitiative from "./ShowInitiative.vue";
import TableResponsable from "@/components/custom/TableResponsable";
import moment from "moment";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import CustomAddButton from "@/components/custom/CustomAddButton";
import DropDownCustom from "@/components/custom/DropDown.vue";
import initiativeKabnan from "@/pages/transformation/initiative/components/initiativeKabnan.vue";
import Gantt from "@/pages/transformation/initiative/components/Gantt.vue";

export default {
  components: {
    TableActions,
    TransferInitiative,
    TableResponsable,
    Modal,
    ShowInitiative,
    AddNewInitiative,
    CustomAddButton,
    DropDownCustom,
    initiativeKabnan,
    Gantt,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    initiatives: {
      type: Array,
    },
    fdrName: {
      type: String,
    },
    responsables: Array,
  },
  methods: {
    echeanceStatus: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "Respectée";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "En retard";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "En cours";
      else return "Nouveau";
    },
    dateBackgroundColor: function (createdAt, deadline, status) {
      var createdAt1 = moment(
        moment(createdAt, "DD/MM/YYYY h:m:s"),
        "DD-MM-YYYY"
      );

      if (status === "Terminé") return "#38b000";
      else if (
        moment
          .duration(
            moment(deadline, "DD-MM-YYYY").diff(
              moment(new Date(), "DD-MM-YYYY")
            )
          )
          .asDays() +
          1 <
        0
      )
        return "#d20f35";
      else if (
        moment
          .duration(
            moment(new Date(), "DD-MM-YYYY").diff(
              moment(createdAt1, "DD-MM-YYYY")
            )
          )
          .asDays() < 5
      )
        return "#ffd60a";
      else return "#38b000";
      // },
    },
    deleteInitiative(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("initiative/deleteInitiative", id).then(() => {
            this.$store
              .dispatch("fdr/fetchFdr", this.$route.params.id)
              .then(() => {
                Swal.fire("Supprimé!", "", "success");
              });
          });
        }
      });
    },
    editInitiative(item, id) {
      this.$store
        .dispatch("fdr/fetchFdrInitiatives", this.$route.params.id)
        .then(() => {
          this.selectedUser = `/api/users/${item.responsable.id}`;
          this.selectedPhase = item.phases.length
            ? `/api/transformation/phases/${item.phases[0].id}`
            : null;
          this.tableItems = this.tableItems.map((init) => {
            return init.id === id ? { ...init, editMode: true } : init;
          });
        });
    },
    confirmInitiative(item) {
      if (
        !(
          this.selectedPhase === null ||
          this.selectedUser === null ||
          item.libelle === "" ||
          item.endingAt === ("" || null)
        )
      ) {
        this.$store
          .dispatch("initiative/updateInitiative", {
            id: item.id,
            libelle: item.libelle,
            endingAt: moment(item.endingAt).format("DD/MM/YYYY"),
            // phase: this.selectedPhase,
            phases: [this.selectedPhase],
            responsable: this.selectedUser,
            status: item.status,
          })
          .then(() => {
            this.$store.dispatch(
              "fdr/fetchFdrInitiatives",
              this.$route.params.id
            );
          });
      }
    },
    cancelInitiative(item) {
      if (
        this.selectedPhase !== null ||
        this.selectedUser !== null ||
        item.libelle !== "" ||
        item.status !== "" ||
        item.endingAt !== ("" || null)
      ) {
        (this.selectedPhase = null), (this.selectedUser = null);
        item.libelle = "";
        item.status = "";
        item.endingAt = null;
      } else {
        this.$store.dispatch("fdr/fetchFdr", this.$route.params.id);
      }
    },
    showInitiativeFunction(item) {
      this.selectedInitiative = item;
      this.showInitiative = !this.showInitiative;
    },
    transferInitiative(fdrId) {
      this.$store
        .dispatch("initiative/updateInitiative", {
          id: this.transformedInitiativeId,
          feuilleRoute: `/api/transformation/feuille_routes/${fdrId}`,
        })
        .then(() => {
          this.$store
            .dispatch("fdr/fetchFdrInitiatives", this.$route.params.id)
            .then(() => {
              this.showTransferItem = !this.showTransferItem;
              Swal.fire("L'initiative a bien été transférée !", "", "success");
            });
        });
    },
    //
    onValueChange: function (e) {
      this.selected = e;
    },
    changeView: function (view, val) {
      this.mode =
        val == 0 ? "day" : val == 1 ? "week" : val == 2 ? "month" : "year";
      this.selectedView.text = view.text;
    },
    transform(initiative, initiativeId) {
      this.showTransferItem = true;
      this.transformedInitiativeId = initiativeId;
      this.selectedInitiative = initiative;
    },
    actions() {
      if (
        this.isAdmin ||
        this.isSuper ||
        ((this.isPo || this.isManager) &&
          this.FDR?.responsable.id === this.AuthenticatedUser.id)
      )
        return ["edit", "transfer", "delete"];
      else return [];
    },
  },

  computed: {
    ...mapGetters("status", ["INITIATIVE_STATUS"]), //get all status
    ...mapGetters("phase", ["PHASES"]), //get all phases
    ...mapGetters("fdr", ["FDRS", "FDR", "INITIATIVES"]), //get all phases
    ...mapGetters([
      "isAdmin",
      "isManager",
      "isSuper",
      "isPo",
      "AuthenticatedUser",
    ]),
    phasesList() {
      return this.PHASES.map((phase) => ({
        ...phase,
        value: phase["@id"],
        text: phase.libelle,
      }));
    },
    rows() {
      return this.tableItems.length;
    },
  },
  created() {
    this.$store.dispatch("status/fetchInitiativeStatus");
    this.$store.dispatch("phase/fetchAllPhases");
    this.$store.dispatch("fdr/fetchAllFdrs");
    if (this.$route.params.id)
      this.$store
        .dispatch("fdr/fetchFdrInitiatives", this.$route.params.id)
        .then(() => {
          this.loading = false;
        });
    else {
      this.loading = false;
    }
    if (!this.$route.path.includes("print")) {
      this.tableFields.push({
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      });
    }
  },
  data: () => ({
    loading: true,
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    sortBy: "name",
    showInitiative: false,
    showTransferItem: false,
    showAddInitiative: false,
    selectedUser: null,
    selectedPhase: null,
    transformedInitiativeId: null,
    selectedInitiative: null,
    tableItems: [],
    tableFields: [
      { key: "ref", label: "Ref", sortable: true },
      { key: "libelle", label: "Initiative", sortable: true },
      { key: "responsable", label: "Responsable", sortable: true },
      { key: "endingAt", label: "Échéance", sortable: true },
      { key: "status", label: "Statut", sortable: true },
      { key: "phases", label: "Phase projet", sortable: true },
      // { key: "type", label: "Type objet", sortable: true },
      { key: "avancement", label: "Avancement", sortable: true },
    ],
    selected: 0,
    ganttView: [
      {
        text: "Jour",
        id: 1,
        color: " btn-inverse-primary",
      },
      {
        text: "Semaine",
        id: 2,
        color: "btn-inverse-danger ",
      },
      {
        text: "Mois",
        id: 3,
        color: "btn-inverse-info ",
      },
    ],
    selectedView: {
      text: "Jour",
      id: 1,
      color: " btn-inverse-primary",
    },
    mode: "day",
  }),
  watch: {
    // initiatives() {
    //   this.tableItems = this.initiatives.map((init) => ({
    //     ...init,
    //     endingAt: datePickerFormat(init.endingAt),
    //   }));
    // },
    INITIATIVES() {
      this.tableItems = this.INITIATIVES.initiatives.map((init) => ({
        ...init,
        feuilleRoute: {
          id: this.INITIATIVES.id,
          name: this.INITIATIVES.name,
        },
        endingAt: datePickerFormat(init.endingAt),
      }));
    },
  },
};
</script>

<style>
#dropdown-1__BV_toggle_ {
  background: #38b000 !important;
  border-color: #38b000 !important;
  width: 140px !important;
}
.custom-drop .dropdown-item,
.custom-drop .dropdown-menu {
  padding: 0 !important;
}
</style>
