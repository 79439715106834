<template>
  <div>
    <div class="" style="margin-top: -56px">
      <div class="p-0 w-100 d-flex">
        <div class="row">
          <div class="col-12">
            <p class="h2 text-secondary">
              <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

              <span style="font-size: 18px" class="font-weight-light"
                >Nom :
              </span>
              <span style="font-size: 22px">{{ fdrName }}</span>
            </p>
          </div>
        </div>
        <div class="ml-auto" style="margin-right: 45px">
          <!-- <b-button @click="confirmItem" variant="secondary">Confirmer</b-button> -->
          <b-button
            variant="primary"
            pill
            type="submit"
            @click="confirmItem"
            size="sm"
            style="height: 40px; width: 40px"
            class="d-flex align-items-center justify-content-center"
            ><i class="mdi mdi-check"></i
          ></b-button>
        </div>
      </div>
    </div>
    <div>
      <p class="h4">Nouvelle initiative</p>
    </div>
    <hr class="bg-secondary p-0 col-12 mt-1 mb-3" />
    <div class="row">
      <div class="col-md-6 col-12">
        <div class="row my-3 align-items-center">
          <div class="col-md-4 font-weight-bold">
            <label>Libellé initiative:</label>
          </div>
          <div class="col-md-8">
            <b-form-input
              type="text"
              v-model="newInitiative.libelle"
              placeholder="Libellé initiative"
              required
              :class="{
                'is-invalid':
                  $v.newInitiative.libelle.$error &&
                  $v.newInitiative.libelle.$dirty,
              }"
            ></b-form-input>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newInitiative.libelle.$dirty"
            >
              {{
                !$v.newInitiative.libelle.required
                  ? "Champ obligatoire"
                  : !$v.newInitiative.libelle.minLength
                  ? `Le champ doit contenir au moins ${$v.newInitiative.libelle.$params.minLength.min} caractères.`
                  : ""
              }}
            </span>
            <!--  -->
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4 font-weight-bold">
            <label>Date de création:</label>
          </div>
          <div class="col-md-8">
            <date-picker
              :value="new Date()"
              class="col-12 p-0"
              placeholder="Date fin"
              format="DD/MM/YYYY"
              required
              disabled
            />
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4 font-weight-bold">
            <label>Responsable:</label>
          </div>
          <div class="col-md-8">
            <v-select
              type="text"
              placeholder="Responsabe"
              required
              label="text"
              v-model="newInitiative.responsable"
              :options="responsables"
              :reduce="(responsable) => responsable.value"
              :style="{
                borderColor: '#dc3545',
              }"
            >
              <template class="p-0" #search="{ attributes }">
                <input
                  class="vs__search m-0"
                  v-bind="attributes"
                  style="height: 38.22px"
                />
              </template>
              <template #no-options> Liste vide </template>
            </v-select>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newInitiative.responsable.$dirty"
            >
              {{
                !$v.newInitiative.responsable.required
                  ? "Champ obligatoire"
                  : ""
              }}
            </span>
            <!--  -->
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4 font-weight-bold">
            <label>Statut:</label>
          </div>
          <div class="col-md-8">
            <b-form-select
              type="text"
              :options="statusInitiative"
              v-model="newInitiative.status"
              placeholder="Statut"
              required
              :class="{
                'is-invalid':
                  $v.newInitiative.status.$error &&
                  $v.newInitiative.status.$dirty,
              }"
            ></b-form-select>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newInitiative.status.$dirty"
            >
              {{ !$v.newInitiative.status.required ? "Champ obligatoire" : "" }}
            </span>
            <!--  -->
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4 font-weight-bold">
            <label>FdR:</label>
          </div>
          <div class="col-md-8">
            <b-form-input
              disabled
              type="text"
              :value="fdrName"
              placeholder="FdR"
              required
            ></b-form-input>
          </div>
        </div>
        <div class="row my-3 align-items-center">
          <div class="col-md-4">
            <label class="font-weight-bold">Domaine:</label>
          </div>
          <div class="col-md-8">
            <b-form-input
              type="text"
              placeholder="Domaine"
              v-model="newInitiative.domaine"
              required
              :class="{
                'is-invalid':
                  $v.newInitiative.domaine.$error &&
                  $v.newInitiative.domaine.$dirty,
              }"
            ></b-form-input>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newInitiative.domaine.$dirty"
            >
              {{
                !$v.newInitiative.domaine.required ? "Champ obligatoire" : ""
              }}
            </span>
            <!--  -->
          </div>
        </div>
      </div>

      <div class="col-md-6 col-12">
        <div class="row my-3 align-items-center">
          <div class="col-md-4 font-weight-bold">
            <label>Patrimoine:</label>
          </div>
          <div class="col-md-8">
            <b-form-select
              type="text"
              :options="patrimoineList"
              v-model="newInitiative.patrimoine"
              placeholder="Patrimoine"
              required
              :class="{
                'is-invalid':
                  $v.newInitiative.patrimoine.$error &&
                  $v.newInitiative.patrimoine.$dirty,
              }"
            ></b-form-select>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newInitiative.patrimoine.$dirty"
            >
              {{
                !$v.newInitiative.patrimoine.required ? "Champ obligatoire" : ""
              }}
            </span>
            <!--  -->
          </div>
        </div>
        <div class="row my-3 align-items-center">
          <div class="col-md-4 font-weight-bold">
            <label>Type initiative:</label>
          </div>
          <div class="col-md-8">
            <b-form-select
              type="text"
              :options="initiativeTypes"
              v-model="newInitiative.type"
              placeholder="Type initiative"
              required
              :class="{
                'is-invalid':
                  $v.newInitiative.type.$error && $v.newInitiative.type.$dirty,
              }"
            ></b-form-select>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newInitiative.type.$dirty"
            >
              {{ !$v.newInitiative.type.required ? "Champ obligatoire" : "" }}
            </span>
            <!--  -->
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4 font-weight-bold">
            <label>Phase initiative:</label>
          </div>
          <div class="col-md-8">
            <b-form-select
              type="text"
              placeholder="Phase Initiative"
              v-model="newInitiative.phase"
              :options="refPhasesList"
              required
              :class="{
                'is-invalid':
                  $v.newInitiative.phase.$error &&
                  $v.newInitiative.phase.$dirty,
              }"
            ></b-form-select>
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newInitiative.phase.$dirty"
            >
              {{ !$v.newInitiative.phase.required ? "Champ obligatoire" : "" }}
            </span>
            <!--  -->
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4 font-weight-bold">
            <label>Description:</label>
          </div>
          <div class="col-md-8">
            <b-textarea
              type="text"
              v-model="newInitiative.description"
              placeholder="Description"
              required
            ></b-textarea>
          </div>
        </div>

        <div class="row my-3 align-items-center">
          <div class="col-md-4 font-weight-bold">
            <label>Les dates:</label>
          </div>
          <div class="col-md-8 pr-0 row">
            <date-picker
              class="col-6 pr-0"
              placeholder="Date début"
              format="DD/MM/YYYY"
              v-model="newInitiative.startedAt"
              required
              :input-attr="{
                class: {
                  'is-invalid':
                    $v.newInitiative.startedAt.$error &&
                    $v.newInitiative.startedAt.$dirty,
                  ' form-control': true,
                },
              }"
            />

            <date-picker
              class="col-6 pr-0 pl-1"
              placeholder="Date fin"
              format="DD/MM/YYYY"
              v-model="newInitiative.endingAt"
              required
              :input-attr="{
                class: {
                  'is-invalid':
                    $v.newInitiative.endingAt.$error &&
                    $v.newInitiative.endingAt.$dirty,
                  ' form-control': true,
                },
              }"
            />
            <div class="row col-12">
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger col-6 font-weight-normal"
                v-if="$v.newInitiative.startedAt.$dirty"
              >
                {{
                  !$v.newInitiative.startedAt.required
                    ? "Champ obligatoire"
                    : ""
                }}
              </span>
              <!--  -->
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger col-6 font-weight-normal"
                v-if="$v.newInitiative.endingAt.$dirty"
              >
                {{
                  !$v.newInitiative.endingAt.required ? "Champ obligatoire" : ""
                }}
              </span>
              <!--  -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import VueSlideBar from "vue-slide-bar";
import Swal from "sweetalert2";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import moment from "moment";
export default {
  // components: {
  //   VueSlideBar,
  // },
  props: {
    fdrName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      newInitiative: {
        libelle: "",
        responsable: "",
        status: "",
        feuilleRoute: `/api/transformation/feuille_routes/${this.$route.params.id}`,
        patrimoine: "",
        domaine: "",
        type: "",
        phase: "",
        description: "",
        avancement: 0,
        endingAt: "",
        startedAt: "",
      },
    };
  },
  validations: {
    newInitiative: {
      libelle: {
        required,
        minLength: minLength(4),
      },
      responsable: {
        required,
      },
      status: {
        required,
      },
      feuilleRoute: {
        required,
      },
      patrimoine: {
        required,
      },
      domaine: {
        required,
      },
      type: {
        required,
      },
      phase: {
        required,
      },
      avancement: 0,
      endingAt: {
        required,
      },
      startedAt: {
        required,
      },
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchInitiativeStatus");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchInitiativeTypes");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("entity/fetchAllEntities");
    this.$store.dispatch("phase/fetchAllPhases");
    this.$store.dispatch("fdr/fetchAllFdrs");
    this.$store.dispatch("refPhase/fetchAllRefPhases");
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["INITIATIVE_STATUS"]), //get all status
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("type", ["INITIATIVE_TYPES"]),
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("entity", ["ENTITIES"]),
    ...mapGetters("refPhase", ["REF_PHASES"]), //get all phases

    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        value: `/api/users/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    statusInitiative() {
      return this.INITIATIVE_STATUS;
    },
    organisations() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: organisation["@id"],
        text: organisation.libelle,
      }));
    },
    patrimoineList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        value: patrimoine["@id"],
        text: patrimoine.name,
      }));
    },
    initiativeTypes() {
      return this.INITIATIVE_TYPES;
    },
    entityList() {
      return this.ENTITIES.map((entite) => ({
        value: `api/referentiel/entites/${entite.id}`,
        text: entite.libelle,
      }));
    },
    refPhasesList() {
      return this.REF_PHASES.map((phase) => phase.name);
    },
  },
  methods: {
    confirmItem: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var initiative = {
          ...this.newInitiative,
          startedAt: moment(this.newInitiative.startedAt, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          ),
          endingAt: moment(this.newInitiative.endingAt, "DD/MM/YYYY").format(
            "DD/MM/YYYY"
          ),
        };
        this.$store
          .dispatch("initiative/createInitiative", initiative)
          .then(() => {
            this.$emit("close");

            this.$store
              .dispatch("fdr/fetchFdr", this.$route.params.id)
              .then(() => {
                Swal.fire({
                  title: "L'initiative est bien créée !",
                  type: "success",
                  confirmButtonText: "OK",
                });
              });
          });
        window.console.table(this.newInitiative);
        window.console.table(initiative);
      }
    },
  },
};
</script>

<style>
.mx-datepicker {
  width: 100% !important;
}
.mx-input-wrapper {
  height: 44.22px !important;
}
.mx-datepicker-popup {
  z-index: 3000 !important;
}
::-webkit-input-placeholder {
  color: #cfcfcf;
}
.v-select,
.vs__dropdown-toggle {
  height: 44.22px;
}
.vs__search {
  color: #cfcfcf;
}
</style>
