var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Kanban',{attrs:{"stages":_vm.STATUS_INITIATIVES,"blocks":_vm.items},on:{"dragged":_vm.onDragged}},[_vm._l((_vm.STATUS_INITIATIVES),function(stage){return _c('div',{key:stage,attrs:{"slot":stage},slot:stage},[_c('h2',[_vm._v(" "+_vm._s(stage)+" ")])])}),_vm._l((_vm.items),function(item){return _c('div',{key:item.id,attrs:{"slot":item.id},slot:item.id},[_c('div',[_c('b-progress',{attrs:{"value":item.avancement}},[_c('b-progress-bar',{style:(`background-color:${
            item.status === 'En cours'
              ? '#4287f5'
              : item.status === 'Validé'
              ? '#a35837'
              : item.status === 'Proposé'
              ? '#1d3557'
              : item.status === 'Suspendu'
              ? 'red'
              : item.status === 'Terminé'
              ? '#0f7316'
              : 'gray'
          };`),attrs:{"value":item.avancement}})],1),_c('div',{staticClass:"d-flex justify-content-between"},[_c('p',{staticClass:"task-date"},[_vm._v(_vm._s(item.startedAt))]),_c('b-dropdown',{staticClass:"transparent",attrs:{"right":""}},[_c('template',{slot:"button-content"},[_c('i',{staticClass:"mdi mdi-dots-vertical"})]),_c('b-dropdown-item',{attrs:{"role":"link","to":{
              name: 'show-initiative',
              params: {
                slug: item.slug || 'abcde',
                id: item.id,
              },
            }}},[_vm._v(" Consulter ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.$emit('transferItem', item, item.id)}}},[_vm._v("Transférer")]),_c('b-dropdown-item',[_vm._v("Supprimer")])],2)],1),_c('div',{staticClass:"d-flex"},[_c('h4',{staticClass:"task-title my-auto"},[_c('router-link',{attrs:{"to":{
              name: 'show-initiative',
              params: { id: item.id, slug: item.slug || 'abcde' },
            }}},[_vm._v(_vm._s(item.libelle))])],1),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
            _vm.echeanceStatus(item.createdAt, item.endingAt, item.status).toUpperCase()
          ),expression:"\n            echeanceStatus(item.createdAt, item.endingAt, item.status).toUpperCase()\n          ",modifiers:{"hover":true}}],staticClass:"text-white my-auto ml-auto text-center px-2 py-0 rounded",style:({
            backgroundColor: _vm.dateBackgroundColor(
              item.createdAt,
              item.endingAt,
              item.status
            ),
          })},[_vm._v(_vm._s(_vm.moment(item.endingAt, "DD-MM-YYYY").format("DD/MM/YYYY")))])]),_c('span',{staticClass:"w-100"},[_vm._v(" "+_vm._s(item.feuilleRoute ? item.feuilleRoute.name : "-")+" ")]),_c('div',{staticClass:"image-grouped mt-1"},[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
            item.responsable.firstname + ' ' + item.responsable.lastname
          ),expression:"\n            item.responsable.firstname + ' ' + item.responsable.lastname\n          ",modifiers:{"hover":true}}],staticClass:"mr-1",attrs:{"variant":"primary"}},[_c('div',{staticStyle:{"width":"20px","height":"20px","border-radius":"15px","display":"flex","align-items":"center","justify-content":"center"}},[_vm._v(" "+_vm._s(item.responsable.firstname[0] + "." + item.responsable.lastname[0])+" ")])])],1),_c('div',{staticClass:"d-lg-flex justify-content-between align-items-center"},[_c('small',{staticClass:"ml-auto text-gray",staticStyle:{"position":"relative","bottom":"-10px"}},[_vm._v(" "+_vm._s(_vm.delayByDays(item.endingAt) >= 0 ? ` Délai de livraison ${_vm.delayByDays(item.endingAt)} jour(s) ` : `${-_vm.delayByDays(item.endingAt)} jour(s) de retard`)+" ")])])],1)])}),_vm._l((_vm.STATUS_INITIATIVES),function(stage){return _c('div',{key:stage,attrs:{"slot":`footer-${stage}`},on:{"click":function($event){return _vm.$router.push({ name: 'add-initiative' })}},slot:`footer-${stage}`},[_c('span',{attrs:{"href":""}},[_vm._v("+ Ajouter un nouveau bloc")])])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }