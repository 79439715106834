<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto">Liste des objectifs</span>
      <div
        class="d-flex justify-content-end"
        v-if="!$router.currentRoute.path.includes('print')"
      >
        <CustomAddButton
          text="Affecter objectif"
          @click="addStrategyChallengeInputs"
          :disabled="disabled"
        />
      </div>
    </div>
    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <!-- {{ tableItems }} -->
    <b-table
      :items="tableItems"
      :fields="tableFields"
      responsive
      :per-page="$route.path.includes('print') ? tableItems.length : perPage"
      :current-page="currentPage"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      striped
    >
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(vision)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          <b-link
            v-if="!$router.currentRoute.path.includes('print')"
            :to="{
              name: 'show-vision',
              params: {
                id: data.value.id,
                slug: data.value.slug || 'abcde',
              },
            }"
            >{{ data.value.libelle }}</b-link
          >
          <span v-else>{{ data.value.libelle }}</span>
        </span>

        <v-select
          v-model="selectedVision"
          v-else
          label="text"
          placeholder="Vision"
          style="min-width: max-content"
          :reduce="(vision) => vision.value"
          :options="data.item.id ? visionsList : filtredVisionList"
          appendToBody
          @input="selectedObjectif = null"
        >
          <template #no-options> Liste vide </template>
        </v-select>
      </template>

      <template #cell(libelle)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          <b-link
            v-if="!$router.currentRoute.path.includes('print')"
            :to="{
              name: 'show-objectif',
              params: {
                id: data.item.id,
                slug: data.item.slug || 'abcde',
              },
            }"
            >{{ data.value }}</b-link
          >
          <span v-else>
            {{ data.value }}
          </span>
        </span>

        <v-select
          v-else
          :options="allObjectifList"
          v-model="selectedObjectif"
          appendToBody
          style="min-width: max-content"
          label="text"
          placeholder="Objectif"
          :reduce="(objectif) => objectif.value"
        >
          <template #no-options> Liste vide </template>
        </v-select>
      </template>

      <template #cell(description)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
        }}</span>
        <input v-else type="text" class="form-control" placeholder="" />
      </template>

      <template #cell(responsable)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')"
          ><TableResponsable
            v-if="!$route.path.includes('print')"
            :responsable="data.value || null"
          />
          <span v-else>
            {{ data.value.firstname + " " + data.value.lastname }}
          </span>
        </span>

        <input
          v-else
          type="select"
          class="form-control"
          disabled
          :value="responsable"
          placeholder="responsable"
        />
      </template>

      <template #cell(organisationResp)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.value
            ? `${data.value.entite.libelle}/${data.value.libelle}`
            : "-"
        }}</span>
        <input
          v-else
          type="text"
          class="form-control"
          disabled
          placeholder="organisation"
          :value="
            organisation
              ? `${organisation.entite.libelle}/${organisation.libelle}`
              : ''
          "
        />
      </template>
      <!--     -->
      <template #cell(actions)="data">
        <table-actions
          :actions="['edit', 'delete']"
          :editMode="data.item.hasOwnProperty('editMode')"
          @confirmItem="confirmItem(data.item, data.index)"
          @cancelItem="cancelItem(data.item, data.index)"
          @editItem="editItem(data.item.id)"
          @deleteItem="deleteItem(data.item.id)"
        ></table-actions>
      </template>
    </b-table>
    <b-pagination
      v-if="!$route.path.includes('print')"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import TableResponsable from "@/components/custom/TableResponsable";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
export default {
  components: {
    TableActions,
    TableResponsable,
    CustomAddButton,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    objectifs: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    new: false,
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    tableItems: [],
    tableFields: [
      { key: "vision", label: "Vision", sortable: true },
      {
        key: "libelle",
        label: "Objectif stratégique",
        sortable: true,
      },
      {
        key: "responsable",
        label: "Responsable",
        sortable: true,
      },
      {
        key: "organisationResp",
        label: "Organisation",
        sortable: true,
      },
    ],
    selectedVision: null,
    selectedObjectif: null,
    objectif: {
      responsable: null,
      organisationResp: null,
    },
  }),
  methods: {
    addStrategyChallengeInputs() {
      this.tableItems = !this.tableItems.some((item) =>
        item.editMode ? item.editMode : false
      )
        ? [
            {
              editMode: true,
              // actions: ["confirm", "cancel"],
            },
            ...this.tableItems,
          ]
        : this.tableItems;
      this.currentPage = 1;
    },
    removeStrategyChallengeInputs(index) {
      var challenge = this.tableItems.find(
        (item, itemIndex) => itemIndex == index
      );
      var onEditMode = challenge.editMode ? true : false;
      if (!onEditMode)
        this.tableItems = this.tableItems.filter(
          (item, itemIndex) => itemIndex !== index
        );
      else
        this.tableItems = this.tableItems.map((item, itemIndex) => {
          itemIndex === index
            ? {
                vision: item.vision,
                libelle: item.libelle,
                description: item.description,
                responsable: item.responsable,
                entity: item.entity,
                organisation: item.organisation,
              }
            : item;
        });
    },
    getObjectifVision(item) {
      this.VISIONS.find((vis) =>
        vis.objectifs.length
          ? vis.objectifs.find((objectif) => objectif.id === item.id)
          : false
      );
    },
    confirmItem(item, index) {
      this.tableItems = this.tableItems.map((challenge, i) => {
        return index === i
          ? {
              vision: {
                id: this.visionsList.find(
                  (vision) => vision.value === this.selectedVision
                ).value,
                libelle: this.visionsList.find(
                  (vision) => vision.value === this.selectedVision
                ).text,
              },
              libelle: this.objectifList.find(
                (vision) => vision.value === this.selectedObjectif
              ).text,
              id: this.objectifList.find(
                (vision) => vision.value === this.selectedObjectif
              ).value,
              responsable: this.objectif.responsable,
              organisationResp: this.organisation,
              // actions: ["edit", "delete"],
            }
          : challenge;
      });
      this.selectedObjectif = null;
      this.selectedVision = null;
      this.objectif = {};
      this.$store
        .dispatch("fdr/updateFdr", {
          id: this.$route.params.id,
          objectifs: this.tableItems.map(
            (item) => `/api/strategy/objectifs/${item.id}`
          ),
        })
        .then(() => {
          this.$store
            .dispatch("fdr/fetchFdr", this.$route.params.id)
            .then(() => {
              this.$store.dispatch("objectif/fetchAllObjectifs");
              this.$store.dispatch("vision/fetchAllVisions");
              Swal.fire({
                title: "L'objectif est bien affecté !",
                type: "success",
                confirmButtonText: "OK",
              });
              this.selectedObjectif = null;
              this.selectedVision = null;
              this.objectif = {};
            });
        });
    },

    editItem(id) {
      this.$store.dispatch("fdr/fetchFdr", this.$route.params.id).then(() => {
        this.tableItems = this.tableItems.map((challenge) => {
          if (id === challenge.id) {
            this.selectedObjectif = challenge.id;
            this.selectedVision = challenge.vision.id;

            this.objectif = {
              responsable: challenge.responsable,
              organisationResp: challenge.organisationResp,
            };
          }
          return id === challenge.id
            ? {
                ...challenge,
                editMode: true,
              }
            : challenge;
        });
      });
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.tableItems = this.tableItems.filter((item) => id !== item.id);
          this.$store
            .dispatch("fdr/updateFdr", {
              id: this.$route.params.id,
              objectifs: this.tableItems.map(
                (item) => `/api/strategy/objectifs/${item.id}`
              ),
            })
            .then(() => {
              Swal.fire("Supprimé!", "", "success");
            });
        }
      });
    },

    shallowEqual: function () {
      return this.selectedVision === null && this.selectedObjectif === null;
    },
    cancelItem(item, itemIndex) {
      var objectif = this.tableItems.find(
        (objectif, index) => index === itemIndex
      );
      if (this.shallowEqual()) {
        if (objectif.old !== true)
          this.tableItems = this.tableItems.filter(
            (objectif, index) => index !== itemIndex
          );
        else {
          this.$store.dispatch("fdr/fetchFdr", this.$route.params.id);
        }
      } else {
        (this.selectedVision = null),
          (this.selectedObjectif = null),
          (this.objectif = {
            responsable: null,
            organisationResp: null,
          }),
          (this.tableItems = this.tableItems.map((contributer, index) =>
            index === itemIndex
              ? {
                  old: contributer.old,
                  editMode: true,
                }
              : contributer
          ));
      }
    },
    vision(item) {
      return this.VISIONS.find((vision) =>
        vision.objectifs.map((objectif) => objectif.id === item.id)
      );
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchFdrTypes");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("entity/fetchAllEntities");
    this.$store.dispatch("objectif/fetchAllObjectifs");
    this.$store.dispatch("vision/fetchAllVisions");
    if (!this.$route.path.includes("print")) {
      this.tableFields.push({
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },
        sortable: false,
      });
    }
  },

  computed: {
    ...mapGetters("vision", ["VISIONS"]), //get all status
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("objectif", ["OBJECTIFS"]), //get all organisations

    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        value: responsable["@id"],
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    responsable() {
      return this.objectif && this.objectif.responsable
        ? `${this.objectif.responsable.firstname} ${this.objectif.responsable.lastname}`
        : "";
    },
    organisation() {
      return this.objectif && this.objectif.organisationResp
        ? this.objectif.organisationResp
        : "";
    },
    filtredVisionList() {
      return this.VISIONS.filter(
        (vision) =>
          vision.objectifs.filter(
            (objectif) =>
              !!this.tableItems.find((item) => item.id == objectif.id)
          ).length < vision.objectifs.length
      ).map((vision) => ({
        value: vision.id,
        text: vision.libelle,
      }));
    },
    visionsList() {
      return this.VISIONS.map((vision) => ({
        value: vision.id,
        text: vision.libelle,
      }));
    },
    objectifList() {
      return this.selectedVision
        ? this.VISIONS.find((vision) => vision.id === this.selectedVision)
            .objectifs.map((objectif) => ({
              value: objectif.id,
              text: objectif.libelle,
            }))
            .filter(
              (objectif) =>
                !this.tableItems.find((item) => item.id === objectif.value)
            )
        : [];
    },
    allObjectifList() {
      if (!this.selectedObjectif)
        return this.OBJECTIFS.filter((objectif) =>
          this.selectedVision
            ? objectif.vision.id === this.selectedVision
            : false
        )
          .map((objectif) => ({
            value: objectif.id,
            text: objectif.libelle,
          }))
          .filter(
            (objectif) =>
              !this.tableItems.find((item) => item.id === objectif.value)
          );

      return this.OBJECTIFS.filter((objectif) =>
        this.selectedVision ? objectif.vision.id === this.selectedVision : false
      )
        .map((objectif) => ({
          value: objectif.id,
          text: objectif.libelle,
        }))
        .filter(
          (objectif) =>
            !this.tableItems.find(
              (item) =>
                item.id !== this.selectedObjectif && item.id === objectif.value
            )
        );
    },

    organisations() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: organisation["@id"],
        text: organisation.libelle,
      }));
    },
    rows() {
      return this.tableItems.length;
    },
  },

  watch: {
    selectedObjectif: function () {
      if (this.selectedObjectif) {
        this.objectif = this.OBJECTIFS.find(
          (objectif) => objectif.id === this.selectedObjectif
        );
      } else {
        this.objectif = null;
      }
    },
    selectedVision: function () {},
    objectifs: function () {
      this.tableItems = this.objectifs.map((item) => ({
        ...item,
        //get the responsable of the objectif
        responsable: this.OBJECTIFS.find((obj) => obj.id === item.id)
          ? this.OBJECTIFS.find((obj) => obj.id === item.id).responsable
          : null,
        //get the organisation of the objectif
        organisationResp: this.OBJECTIFS.find((obj) => obj.id === item.id)
          ? this.OBJECTIFS.find((obj) => obj.id === item.id).organisationResp
          : null,
        vision: this.VISIONS.find((vis) =>
          vis.objectifs.length
            ? vis.objectifs.find((objectif) => objectif.id === item.id)
            : false
        ),
        old: true,
      }));
    },
    VISIONS: function () {
      this.tableItems = this.objectifs.map((item) => ({
        ...item,
        //get the responsable of the objectif
        responsable: this.OBJECTIFS.find((obj) => obj.id === item.id)
          ? this.OBJECTIFS.find((obj) => obj.id === item.id).responsable
          : null,
        //get the organisation of the objectif
        organisationResp: this.OBJECTIFS.find((obj) => obj.id === item.id)
          ? this.OBJECTIFS.find((obj) => obj.id === item.id).organisationResp
          : null,
        //get vision
        vision: this.VISIONS.find((vis) =>
          vis.objectifs.length
            ? vis.objectifs.find((objectif) => objectif.id === item.id)
            : false
        ),
        old: true,
      }));
    },
    OBJECTIFS: function () {
      this.tableItems = this.objectifs.map((item) => ({
        ...item,
        //get the responsable of the objectif
        responsable: this.OBJECTIFS.find((obj) => obj.id === item.id)
          ? this.OBJECTIFS.find((obj) => obj.id === item.id).responsable
          : null,
        //get the organisation of the objectif
        organisationResp: this.OBJECTIFS.find((obj) => obj.id === item.id)
          ? this.OBJECTIFS.find((obj) => obj.id === item.id).organisationResp
          : null,
        vision: this.VISIONS.find((vis) =>
          vis.objectifs.length
            ? vis.objectifs.find((objectif) => objectif.id === item.id)
            : false
        ),
        old: true,
      }));
    },
  },
};
</script>

<style></style>
