<template>
  <div>
    <div class="d-flex">
      <span class="h4 text-secondary mr-auto my-auto">Liste des comités </span>
      <div
        class="d-flex justify-content-end"
        v-if="!$router.currentRoute.path.includes('print')"
      >
        <CustomAddButton
          text="Nouvelle inscription"
          :disabled="disabled"
          @click="addInput"
        />
      </div>
    </div>

    <div class="col-12 p-0">
      <hr class="mt-1 mb-3 bg-secondary" />
    </div>
    <b-table
      responsive
      :fields="tableFields"
      :items="tableItems"
      show-empty
      bordered
      striped
      :per-page="$route.path.includes('print') ? tableItems.length : perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :busy="loading"
    >
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template #cell(comite)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{
            data.item.inscription
              ? data.item.inscription.sessionComite.planning.comite.name
              : "-"
          }}
          <!-- {{data.item}} -->
        </span>
        <v-select
          v-else
          appendToBody
          style="min-width: max-content"
          label="text"
          :disabled="data.item.id ? true : false"
          :reduce="(comite) => comite.value"
          :options="comitesList"
          v-model="selectedComite"
          placeholder="Comité"
        >
          <template #no-options> Liste vide </template>
        </v-select>
      </template>

      <template #cell(hour)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          <!-- {{ chronologiesList }} -->
          {{
            data.item.inscription.sessionComite && data.item.inscription
              ? moment(
                  data.item.inscription.sessionComite.hourStart,
                  "HH:mm:ss"
                ).format("HH:mm") +
                " - " +
                moment(
                  data.item.inscription.sessionComite.hourEnd,
                  "HH:mm:ss"
                ).format("HH:mm")
              : "-"
          }}
        </span>
        <v-select
          v-else
          appendToBody
          style="min-width: max-content"
          label="text"
          :disabled="data.item.id ? true : false"
          :reduce="(comite) => comite.value"
          :options="chronologiesList"
          v-model="selectedChronologie"
          placeholder="Chronologie"
        >
          <template #no-options> Liste vide </template>
        </v-select>
        <!-- {{ chronologiesList }} -->
      </template>

      <template #cell(comite_date)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          <b-link
            v-if="!$route.path.includes('print')"
            :to="{
              name: 'show-session',
              params: {
                id: data.item.inscription.sessionComite.id,
                slug: data.item.inscription.sessionComite.slug || 'abcde',
              },
            }"
            >{{
              data.item.inscription
                ? moment(
                    data.item.inscription.sessionComite.date,
                    "DD/MM/YYYY"
                  ).format("DD/MM/YYYY")
                : "-"
            }}
          </b-link>
          <span v-else>
            {{
              data.item.inscription
                ? moment(
                    data.item.inscription.sessionComite.date,
                    "DD/MM/YYYY"
                  ).format("DD/MM/YYYY")
                : "-"
            }}
          </span>
        </span>

        <date-picker
          format="DD/MM/YYYY"
          v-model="selectedSession"
          :disabled-date="disabledDates"
          :disabled="data.item.id ? true : false"
          placeholder="Date"
          v-else
        ></date-picker>
      </template>
      <template #cell(createdAt)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">
          {{
            data.item.inscription
              ? moment(
                  data.item.inscription.date,
                  "DD/MM/YYYY HH:mm:ss"
                ).format("DD/MM/YYYY")
              : "-"
          }}</span
        >
        <date-picker
          disabled
          format="DD/MM/YYYY"
          v-model="createdAt"
          v-else
        ></date-picker>
      </template>

      <template #cell(objetPassage)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')"
          >{{ data.item.inscription.objetPassage }}
        </span>
        <v-select
          v-else
          :options="['Avis', 'Decision', 'Information']"
          appendToBody
          style="min-width: max-content"
          v-model="objetPassage"
          placeholder="Objet passage"
        >
          <template #no-options> Liste vide </template>
        </v-select>
      </template>
      <template #cell(decision)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.item.inscription ? data.item.inscription.decision.decision : "-"
        }}</span>
        <v-select
          v-model="decision"
          :disabled="data.item.id ? false : true"
          v-else
          appendToBody
          style="min-width: max-content"
          :options="[
            'En attente',
            'Ajourné',
            'Favorable',
            'Recommandations',
            'Aucune',
          ]"
        ></v-select>
      </template>

      <template v-slot:cell(rangPassage)="data">
        <div v-if="!data.item.hasOwnProperty('editMode')">
          <span>{{ data.item.inscription.rangPassage }}</span>
        </div>
        <div v-else style="width: 150px !important">
          <v-select
            v-model="rangPassage"
            appendToBody
            disabled
            style="min-width: max-content"
            placeholder="Rang passage"
            :options="rangePassagesList"
          ></v-select>
        </div>
      </template>

      <template v-slot:cell(temps)="data">
        <div v-if="!data.item.hasOwnProperty('editMode')">
          <span>{{
            data.item.inscription ? data.item.inscription.temps : "-"
          }}</span>
        </div>
        <div v-else style="width: 150px !important">
          <v-select
            :options="[
              '0min',
              '15min',
              '30min',
              '45min',
              '1h',
              '1h15min',
              '1h30min',
              '1h45min',
              '2h',
              '2h15min',
              '2h30min',
              '2h45min',
              '3h',
            ]"
            append-to-body
            v-model="temps"
            placeholder="Temps"
            style="padding-right: 0 !important"
            :input-attr="{
              class: {
                'is-invalid': $v.temps.$error && $v.temps.$dirty,
                ' form-control': true,
              },
            }"
            :class="{
              'is-invalid form-control p-0': $v.temps.$error && $v.temps.$dirty,
            }"
          ></v-select>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.temps.$dirty"
          >
            {{ !$v.temps.required ? "champ obligatoire" : "" }}
          </span>
        </div>
      </template>

      <template #cell(presentator)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')"
          ><TableResponsable
            v-if="!$route.path.includes('print')"
            :responsable="
              data.item.inscription ? data.item.inscription.user : null
            "
          />
          <span v-else>
            {{
              data.item.inscription.user.firstname +
              " " +
              data.item.inscription.user.lastname
            }}
          </span>
        </span>

        <v-select
          appendToBody
          style="min-width: max-content"
          label="text"
          :reduce="(presontator) => presontator.value"
          v-model="selectedPresontateur"
          v-else
          :options="responsables"
          placeholder="Présentateur"
        >
          <template #no-options> Liste vide </template>
        </v-select>
      </template>

      <template #cell(status)="data">
        <span v-if="!data.item.hasOwnProperty('editMode')">{{
          data.item.inscription ? data.item.inscription.status : "-"
        }}</span>
        <v-select
          v-else
          :options="INSCRIPTION_STATUS"
          appendToBody
          disabled
          v-model="status"
          style="min-width: max-content"
        >
          <template #no-options> Liste vide </template>
        </v-select>
      </template>

      <template #cell(filename)="data">
        <span class="d-flex" v-if="!data.item.hasOwnProperty('editMode')">
          <a
            v-if="data.value"
            style="max-width: 100px"
            :href="IMG_URL + data.item.fileUrl"
            target="_blank"
            class="text-truncate"
            >{{ data.value }}</a
          >
          <span v-else>-</span>
        </span>
        <b-form-file id="file" v-else></b-form-file>
      </template>

      <template #cell(guests)="data">
        <b-badge
          style="
            cursor: pointer;
            background-color: #adb5bd;
            border-color: #adb5bd;
          "
          v-if="!data.item.hasOwnProperty('editMode')"
          pill
          >{{
            data.item.dossier
              ? data.item.dossier.inscription.invite.users.length
              : 0
          }}</b-badge
        >

        <div
          v-else
          class="d-flex flex-wrap justify-content-center align-items-center"
        >
          <span @click="searchGuestsModal = true"
            ><i
              style="font-size: 20px; color: #007bff"
              class="mdi mdi-plus-circle"
            ></i
          ></span>
        </div>
      </template>

      <template #cell(actions)="data">
        <table-actions
          :actions="['delete', 'edit']"
          :editMode="data.item.editMode"
          @cancelItem="cancelItem(data.item)"
          @confirmItem="confirmItem(data.item)"
          @deleteItem="deleteItem(data.item.id)"
          @editItem="editItem(data.item)"
        />
      </template>
    </b-table>
    <b-pagination
      v-if="!$route.path.includes('print')"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>

    <!-- Search Guests -->
    <div to="modals-xyz-548" v-if="searchGuestsModal">
      <modal @close="searchGuestsModal = !searchGuestsModal" action="confirm">
        <search-guests
          @close="searchGuestsModal = !searchGuestsModal"
          :users="selectedUsers"
          @confirmUsers="setinvites"
        />
      </modal>
    </div>

    <!-- INVITES LIST MODAL -->
    <div to="modals-xyz-548" v-if="showInvites">
      <modal @close="showInvites = !showInvites" action="confirm">
        <invites-list
          :fdrName="COMITE.name"
          :dossierName="dossierName"
          :inscription="inscription"
          @close="showInvites = !showInvites"
        />
      </modal>
    </div>
  </div>
</template>

<script>
import TableActions from "@/components/custom/TableActions.vue";
import SearchGuests from "./SearchGuests.vue";
import InvitesList from "./InvitesList.vue";
import Modal from "@/components/custom/Modal.vue";
import { mapGetters } from "vuex";
import TableResponsable from "@/components/custom/TableResponsable.vue";
import Swal from "sweetalert2";
import CustomAddButton from "@/components/custom/CustomAddButton";
import { datePickerFormat } from "@/helpers/DatePickerFormat";
import { IMG_URL } from "@/helpers/services/api";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
// import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import { uniqBy } from "lodash";

export default {
  components: {
    TableActions,
    SearchGuests,
    InvitesList,
    Modal,
    TableResponsable,
    CustomAddButton,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    IMG_URL,
    showInvites: false,
    loading: true,
    dossierName: "",
    inscription: null,
    sortBy: " ",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    searchGuestsModal: false,
    selectedComite: null,
    selectedSession: null,
    selectedPresontateur: null,
    selectedChronologie: null,
    tableItems: [],
    selectedUsers: [],
    tableFields: [
      { key: "comite", label: "Comité", sortable: true },
      { key: "comite_date", label: "Date comité", sortable: true },
      { key: "hour", label: "Chronologie", sortable: true },
      { key: "objetPassage", label: "Objet passage", sortable: true },
      { key: "presentator", label: "Présentateur", sortable: true },
      { key: "createdAt", label: "Date inscription", sortable: true },
      {
        key: "status",
        label: "Statut inscription",
        sortable: true,
      },
      {
        key: "rangPassage",
        label: "Rang passage",
        sortable: true,
      },

      {
        key: "temps",
        label: "Temps",
        sortable: true,
      },
      { key: "filename", label: "Livrable", sortable: true },
      { key: "decision", label: "Décision", sortable: true },
    ],
    temps: "",
    rangPassage: "",
    objetPassage: "",
    status: "",
    decision: "",
    createdAt: new Date(),
  }),
  validations: {
    selectedPresontateur: {
      required,
    },
    objetPassage: {
      required,
    },
    status: {
      required,
    },
    // decision: {
    //   required,
    // },
    // rangPassage: {
    //   required,
    // },
    temps: {
      required,
    },
  },
  created() {
    if (this.$route.params.id)
      this.$store
        .dispatch("fdr/fetchFdrComites", this.$route.params.id)
        .then(() => {
          this.loading = false;
        });
    else {
      this.loading = false;
    }
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("comite/fetchAllComites");
    this.$store.dispatch("manage/fetchAllSessions");
    this.$store.dispatch("status/fetchInscriptionStatus");
    // this.$store.dispatch("inscription/fetchInscription");
    if (!this.$route.path.includes("print")) {
      this.tableFields.push({
        key: "actions",
        label: "Actions",
        thStyle: { width: "150px !important", minWidth: "150px !important" },

        sortable: false,
      });
    }
  },
  methods: {
    datePickerFormat,
    disabledDates(date) {
      let ss = this.SESSIONS.filter(
        (session) =>
          session.planning.comite["@id"] === this.selectedComite &&
          session.status === "Actif"
      );

      ss = ss
        .filter(
          (s) =>
            !this.tableItems.find((item) =>
              item.inscription
                ? moment(
                    item.inscription.sessionComite.date,
                    "DD/MM/YYYY"
                  ).format("DD/MM/YYYY") ===
                    moment(date).format("DD/MM/YYYY") &&
                  s.hourStart == item.inscription.sessionComite.hourStart &&
                  s.hourEnd == item.inscription.sessionComite.hourEnd
                : false
            )
        )
        .filter(
          (session) =>
            moment(session.date, "DD/MM/YYYY").format("DD/MM/YYYY") ===
            moment(date).format("DD/MM/YYYY")
        );

      window.console.log(ss, "sessionsList");
      return !ss.length;
    },
    showInvitesModal(name, inscription) {
      this.dossierName = name;
      this.showInvites = true;
      this.inscription = inscription;
    },
    setinvites(users) {
      this.tableItems = this.tableItems.map((item) =>
        item.editMode
          ? {
              ...item,
              inscription: {
                ...item.inscription,
                invite: {
                  ...item.inscription.invite,
                  users,
                },
              },
            }
          : item
      );
    },
    confirmItem(item) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (!item.id) this.decision = "";
        var sessionId = this.SESSIONS.find(
          (session) =>
            session.planning.comite["@id"] === this.selectedComite &&
            moment(session.date, "DD/MM/YYYY").format("DD/MM/YYYY") ===
              moment(this.selectedSession, "DD/MM/YYYY").format("DD/MM/YYYY")
        )["@id"];
        if (item.id) {
          // get session data
          this.$store
            .dispatch("inscription/updateInscription", {
              id: item.inscription.id,
              user: this.selectedPresontateur,
              objetPassage: this.objetPassage,
              rangPassage: this.rangPassage,
              temps: this.temps,
              status: this.status,
            })
            .then(() => {
              this.rangPassage = 0;

              this.$store
                .dispatch("decision/updateDecision", {
                  id: item.inscription.decision.id,
                  decision: this.decision,
                })
                .then(() => {
                  this.$store
                    .dispatch("fdr/fetchFdrComites", this.$route.params.id)

                    .then(() => {
                      Swal.fire(
                        "L'inscription est mise à jour !",
                        "",
                        "success"
                      );
                    });
                });
            });
          var fileInput = document.getElementById("file").files;
          if (fileInput.length) {
            var formData = new FormData();
            formData.append("file", fileInput[0]);

            this.$store
              .dispatch(
                "dossier/createDossierFile",
                // formData
                {
                  id: item.id,
                  file: formData,
                }
              )
              .then(() => {
                this.$store.dispatch(
                  "fdr/fetchFdrComites",
                  this.$route.params.id
                );
              });
          }
        } else {
          this.$store
            .dispatch("inscription/createInscription", {
              user: this.selectedPresontateur,
              objetPassage: this.objetPassage,
              // rangPassage: parseInt(Math.floor(Math.random() * 445033) || 0),
              rangPassage: this.rangPassage,
              temps: this.temps,
              status: this.status,
              sessionComite: sessionId,
              date: moment().format("DD/MM/YYYY HH:mm:ss"),
            })
            .then(() => {
              this.rangPassage = 0;
              this.$store.dispatch(
                "fdr/fetchFdrComites",
                this.$route.params.id
              );
            });
        }
        this.$store.dispatch("fdr/fetchFdrComites", this.$route.params.id);
        this.selectedChronologie = null;
      }
    },

    addInput() {
      if (
        !this.tableItems.some((item) => (item.editMode ? item.editMode : false))
      ) {
        this.tableItems = !this.tableItems.some((item) =>
          item.editMode ? item.editMode : false
        )
          ? [
              {
                editMode: true,
              },
              ...this.tableItems,
            ]
          : this.tableItems;
        if (
          !this.tableItems.some((item) =>
            item.editMode ? item.editMode : false
          )
        ) {
          this.createdAt = new Date();
        }
        this.decision = "En attente";
        this.status = "Demande";
        this.rangPassage = 0;
      }
    },
    editItem: function (item) {
      window.console.log(item);
      this.$store
        .dispatch("fdr/fetchFdrComites", this.$route.params.id)
        .then(() => {
          this.tableItems = this.tableItems.map((comite) =>
            item.id === comite.id
              ? {
                  ...comite,
                  editMode: true,
                }
              : comite
          );
          this.rangPassage = item.inscription.rangPassage;
          this.objetPassage = item.inscription.objetPassage;
          this.selectedPresontateur = item.inscription.user.id
            ? `/api/users/${item.inscription.user.id}`
            : null;
          this.selectedComite = item.inscription.sessionComite.planning.comite
            ? `/api/gouvernance/comites/${item.inscription.sessionComite.planning.comite.id}`
            : null;
          this.temps = item.inscription.temps;
          this.status = item.inscription.status;
          this.decision = item.inscription.decision.decision;
          this.selectedSession = datePickerFormat(
            item.inscription.sessionComite.date
          );
          this.createdAt = datePickerFormat(
            item.inscription.sessionComite.date
          );
          this.selectedChronologie =
            moment(item.inscription.sessionComite.hourStart, "HH:mm:ss").format(
              "HH:mm"
            ) +
            " - " +
            moment(item.inscription.sessionComite.hourEnd, "HH:mm:ss").format(
              "HH:mm"
            );
        });
    },
    cancelItem: function (item) {
      if (
        (item.id ? true : this.rangPassage === 0) &&
        this.objetPassage === "" &&
        this.selectedPresontateur === "" &&
        this.selectedComite === "" &&
        this.temps === "" &&
        (item.id ? this.decision === "" : true) &&
        this.selectedSession === null
      )
        this.$store.dispatch("fdr/fetchFdrComites", this.$route.params.id);
      else {
        this.rangPassage == item.id ? this.rangPassage : 0;

        this.objetPassage = "";
        this.selectedPresontateur = "";
        this.selectedComite = "";
        this.temps = "";
        if (item.id) this.decision = "";
        if (item.id) this.selectedChronologie = null;
        this.selectedSession = null;
        this.createdAt === new Date();
      }
    },
    deleteItem(id) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("dossier/deleteDossier", parseInt(id))
            .then(() => {
              this.$store
                .dispatch("fdr/fetchFdrComites", this.$route.params.id)
                .then(() => {
                  Swal.fire("Supprimé!", "", "success");
                });
            });
        }
      });
    },
  },
  computed: {
    ...mapGetters("fdr", ["COMITE"]),
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("comite", ["COMITES"]),
    ...mapGetters("manage", ["SESSIONS", "SESSION"]),
    ...mapGetters("status", ["INSCRIPTION_STATUS"]), //get resposables
    ...mapGetters("inscription", ["INSCRIPTION"]), //get resposables

    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        id: `/api/users/${responsable.id}`,
        full_name: responsable.firstname + " " + responsable.lastname,
        value: `/api/users/${responsable.id}`,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    comitesList() {
      return uniqBy(
        this.SESSIONS.filter((session) => session.status == "Actif").map(
          (session) => ({
            text: session.planning.comite.name,
            value: session.planning.comite["@id"],
          })
        ),
        "value"
      );
    },
    sessionsList() {
      if (this.selectedComite)
        return this.SESSIONS.filter(
          (session) =>
            session.planning.comite["@id"] === this.selectedComite &&
            session.status === "Actif"
        ).map((session) =>
          moment(session.date, "DD/MM/YYYY").format("DD/MM/YYYY")
        );
      return [];
    },
    rows() {
      return this.tableItems.length;
    },
    rangePassagesList() {
      return Array.from({ length: 10 }, (_, i) => i + 1).filter(
        (item) =>
          !this.tableItems.find((commite) =>
            commite.inscription
              ? commite.inscription.rangPassage === item
              : false
          )
      );
    },
    chronologiesList() {
      window.console.log(this.SESSIONS, "this.SESSIONS");
      window.console.log(this.tableItems, "this.tableItems");
      return this.selectedSession
        ? this.SESSIONS.filter(
            (session) =>
              moment(session.date, "DD/MM/YYYY").format("DD/MM/YYYY") ===
                moment(this.selectedSession, "DD/MM/YYYY").format(
                  "DD/MM/YYYY"
                ) &&
              !this.tableItems.find((item) =>
                item.inscription && item.inscription.sessionComite
                  ? item.inscription.sessionComite.id == session.id
                  : false
              )
          ).map((session) => ({
            text:
              moment(session.hourStart, "HH:mm:ss").format("HH:mm") +
              " - " +
              moment(session.hourEnd, "HH:mm:ss").format("HH:mm"),
            value: session["@id"],
          }))
        : [];
    },
  },
  watch: {
    COMITE: function () {
      if (this.COMITE)
        this.tableItems = this.COMITE.dossiers.filter(
          (dossier) => dossier.inscription
        );
    },
    INSCRIPTION: function () {
      if (this.INSCRIPTION) {
        var fileInput = "";
        if (document.getElementById("file"))
          fileInput = document.getElementById("file").files;
        var formData = new FormData();
        if (fileInput.length) formData.append("file", fileInput[0]);
        formData.append("inscription", this.INSCRIPTION["@id"]);
        formData.append(
          "feuileroute",
          `/api/transformation/feuille_routes/${this.$route.params.id}`
        );
        formData.append("name", `DO-${this.INSCRIPTION.id}`);
        this.$store.dispatch("dossier/createDossier", formData).then(() => {
          this.$store
            .dispatch("fdr/fetchFdrComites", this.$route.params.id)
            .then(() => {
              Swal.fire("L'inscription est bien créée !", "", "success");
              this.rangPassage = "";
              this.objetPassage = "";
              this.selectedPresontateur = "";
              this.selectedComite = "";
              this.temps = "";
              this.status = "";
              this.decision = "";
              this.selectedSession = null;
              this.createdAt = new Date();
            });
        });
      }
    },
  },
};
</script>

<style></style>
