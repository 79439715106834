<template>
  <div>
    <b-dropdown
      @change="changeDiagramType"
      id="ddown7"
      :text="items[selected]"
      variant="outline-info"
    >
      <div v-for="(item, index) in items" :key="index + 'it drp'">
        <b-dropdown-item @click="changeDiagramType">{{ item }}</b-dropdown-item>
        <b-dropdown-divider
          v-if="index < items.length - 1"
        ></b-dropdown-divider>
      </div>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: "DropDownCustom",

  methods: {
    changeDiagramType: function (e) {
      let index = this.items.findIndex((it) => it == e.target.innerText);
      this.selected = index;

      this.$emit("changeValue", index);
    },
  },
  data() {
    return {
      selected: this.$store.state.views.activeView || 0,
    };
  },
  props: {
    items: Array,
    value: {
      type: [String, Number],
      default: "0",
    },
  },
  mounted() {
    if (this.value != null && this.value != undefined)
      this.selected = parseInt(this.value);
  },

  watch: {
    value() {
      if (this.value != null && this.value != undefined)
        this.selected = parseInt(this.value);
    },
  },
};
</script>
