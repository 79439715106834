var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"h4 text-secondary mr-auto my-auto"},[_vm._v("Liste des objectifs")]),(!_vm.$router.currentRoute.path.includes('print'))?_c('div',{staticClass:"d-flex justify-content-end"},[_c('CustomAddButton',{attrs:{"text":"Affecter objectif","disabled":_vm.disabled},on:{"click":_vm.addStrategyChallengeInputs}})],1):_vm._e()]),_vm._m(0),_c('b-table',{attrs:{"items":_vm.tableItems,"fields":_vm.tableFields,"responsive":"","per-page":_vm.$route.path.includes('print') ? _vm.tableItems.length : _vm.perPage,"current-page":_vm.currentPage,"sort-desc":_vm.sortDesc,"show-empty":"","bordered":"","striped":""},on:{"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"text-center text-gray"},[_vm._v("Table vide")])]},proxy:true},{key:"cell(vision)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[(!_vm.$router.currentRoute.path.includes('print'))?_c('b-link',{attrs:{"to":{
            name: 'show-vision',
            params: {
              id: data.value.id,
              slug: data.value.slug || 'abcde',
            },
          }}},[_vm._v(_vm._s(data.value.libelle))]):_c('span',[_vm._v(_vm._s(data.value.libelle))])],1):_c('v-select',{staticStyle:{"min-width":"max-content"},attrs:{"label":"text","placeholder":"Vision","reduce":(vision) => vision.value,"options":data.item.id ? _vm.visionsList : _vm.filtredVisionList,"appendToBody":""},on:{"input":function($event){_vm.selectedObjectif = null}},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.selectedVision),callback:function ($$v) {_vm.selectedVision=$$v},expression:"selectedVision"}})]}},{key:"cell(libelle)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[(!_vm.$router.currentRoute.path.includes('print'))?_c('b-link',{attrs:{"to":{
            name: 'show-objectif',
            params: {
              id: data.item.id,
              slug: data.item.slug || 'abcde',
            },
          }}},[_vm._v(_vm._s(data.value))]):_c('span',[_vm._v(" "+_vm._s(data.value)+" ")])],1):_c('v-select',{staticStyle:{"min-width":"max-content"},attrs:{"options":_vm.allObjectifList,"appendToBody":"","label":"text","placeholder":"Objectif","reduce":(objectif) => objectif.value},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(" Liste vide ")]},proxy:true}],null,true),model:{value:(_vm.selectedObjectif),callback:function ($$v) {_vm.selectedObjectif=$$v},expression:"selectedObjectif"}})]}},{key:"cell(description)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(_vm._s(data.value))]):_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":""}})]}},{key:"cell(responsable)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[(!_vm.$route.path.includes('print'))?_c('TableResponsable',{attrs:{"responsable":data.value || null}}):_c('span',[_vm._v(" "+_vm._s(data.value.firstname + " " + data.value.lastname)+" ")])],1):_c('input',{staticClass:"form-control",attrs:{"type":"select","disabled":"","placeholder":"responsable"},domProps:{"value":_vm.responsable}})]}},{key:"cell(organisationResp)",fn:function(data){return [(!data.item.hasOwnProperty('editMode'))?_c('span',[_vm._v(_vm._s(data.value ? `${data.value.entite.libelle}/${data.value.libelle}` : "-"))]):_c('input',{staticClass:"form-control",attrs:{"type":"text","disabled":"","placeholder":"organisation"},domProps:{"value":_vm.organisation
            ? `${_vm.organisation.entite.libelle}/${_vm.organisation.libelle}`
            : ''}})]}},{key:"cell(actions)",fn:function(data){return [_c('table-actions',{attrs:{"actions":['edit', 'delete'],"editMode":data.item.hasOwnProperty('editMode')},on:{"confirmItem":function($event){return _vm.confirmItem(data.item, data.index)},"cancelItem":function($event){return _vm.cancelItem(data.item, data.index)},"editItem":function($event){return _vm.editItem(data.item.id)},"deleteItem":function($event){return _vm.deleteItem(data.item.id)}}})]}}])}),(!_vm.$route.path.includes('print'))?_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"table-list","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 p-0"},[_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"})])
}]

export { render, staticRenderFns }