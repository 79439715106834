<template>
  <div style="margin-top: -50px">
    <span class="d-inline-block h3 mb-0"
      >FdR: <span style="font-weight: 600 !important"> Transfo CRM</span></span
    >
    <div class="d-flex align-items-center py-4">
      <span class="d-inline-block w-75 h4 mb-0">Recherche invité:</span>
      <v-select
        class="w-100"
        placeholder="Nom"
        :options="responsables"
        v-model="selectedUser"
        :reduce="(responsable) => responsable.value"
        label="text"
      >
        <template #no-options> Liste vide </template>
      </v-select>
      <v-select
        class="w-100"
        placeholder="Organisation"
        :options="organisations"
        :reduce="(organisation) => organisation.value"
        label="text"
      >
        <template #no-options> Liste vide </template>
      </v-select>
      <b-button
        class="ml-1"
        @click="$emit('confirmUsers', selected) && $emit('close')"
        :disabled="!this.selected.length"
        type=""
      >
        Confirmer</b-button
      >
    </div>
    <!-- {{ users }} -->
    <hr />
    {{ selected }}
    <b-table
      :tbody-tr-class="tbodyRowClass"
      ref="selectableTable"
      :fields="fields"
      :items="filteredUsers"
      responsive
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-empty
      bordered
      striped
      @row-clicked="rowClicked"
      primary-key="id"
    >
      <!-- selectMode="multi" selectable -->
      <!-- FOR LOADING ANIMATIONS -->
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-2"></b-spinner>
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- SHOW IF TABLE IS EMPTY -->
      <template #empty>
        <div class="text-center text-gray">Table vide</div>
      </template>
      <template v-slot:cell(selection)="{ item }">
        <b-checkbox
          @change="rowClicked(item)"
          :checked="item.selected"
        ></b-checkbox>
      </template>
      <!-- <template v-slot:cell(selection)="data">
        <b-checkbox :checked="data.item.selected"></b-checkbox>
      </template> -->

      <template #cell(firstname)="data">
        <span> {{ data.item.firstname + " " + data.item.lastname }}</span>
      </template>
      <template #cell(organisation)="data">
        <span> {{ data.value ? data.value : "-" }}</span>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="table-list"
      align="right"
    >
    </b-pagination>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      sortBy: "name",
      perPage: 4,
      currentPage: 1,
      sortDesc: false,
      sortByFormatted: true,
      filterByFormatted: true,
      sortable: true,
      selectedGuest: null,
      selectedUser: null,
      selected: [],

      fields: [
        { key: "firstname", label: "Nom et prénom" },
        { key: "organisation", label: "Organisation" },
        { key: "selection", label: "Sélection", sortable: true },
      ],
    };
  },
  mounted() {
    // this.$refs.selectableTable.selectRow(3);
    this.selected = this.users.map((user) => user.id);
  },
  methods: {
    rowClicked(item) {
      if (item.selected) {
        this.$set(item, "selected", false);
        this.selected = this.selected.filter((id) => id !== item.id);
      } else {
        this.$set(item, "selected", true);
        this.selected.push(item.id);
      }
    },
    tbodyRowClass(item) {
      /* Style the row as needed */
      if (item.selected) {
        return ["b-table-row-selected", "table-primary", "cursor-pointer"];
      } else {
        return ["cursor-pointer"];
      }
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchFdrStatus");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store.dispatch("type/fetchFdrTypes");
    this.$store.dispatch("patrimoine/fetchAllPatrimoines");
    this.$store.dispatch("entity/fetchAllEntities");
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["FDR_STATUS"]), //get all status
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("type", ["FDR_TYPES"]),
    ...mapGetters("patrimoine", ["PATRIMOINES"]),
    ...mapGetters("entity", ["ENTITIES"]),
    responsables() {
      //return array of responsables

      return this.RESPONSABLES.map((responsable) => ({
        ...responsable,
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    statusFdr() {
      return this.FDR_STATUS;
    },
    organisations() {
      return this.ORGANISATIONS.map((organisation) => ({
        value: `/api/referentiel/organisations/${organisation.id}`,
        text: `${organisation.entite.libelle}/${organisation.libelle}`,
      }));
    },
    fdrTypes() {
      return this.FDR_TYPES;
    },
    patrimoineList() {
      return this.PATRIMOINES.map((patrimoine) => ({
        value: patrimoine.id,
        text: patrimoine.name,
      }));
    },
    filteredUsers() {
      return this.selectedUser
        ? this.RESPONSABLES.filter((user) => user.id === this.selectedUser)
        : this.RESPONSABLES.map((user) => ({
            ...user,
            selected: !!this.users.find((puser) => puser.id === user.id),
          }));
    },
    entityList() {
      return this.ENTITIES.map((entite) => ({
        value: `api/referentiel/entites/${entite.id}`,
        text: entite.libelle,
      }));
    },
    rows() {
      return this.filteredUsers.length;
    },
  },
};
</script>

<style></style>
