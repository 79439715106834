<template>
  <div class="row">
    <div class="col-10 p-0" style="margin-top: -56px">
      <p class="h2 text-secondary mx-3 mt-3 mb-0">
        <!-- <i class="mdi mdi-file-tree"></i> -->
        <i
          :class="$route.meta.iconClass"
          style="font-size: 22px; color: #072648 !important"
        ></i>

        <span style="font-size: 18px" class="font-weight-light">Fiche : </span>
        <span style="font-size: 22px">{{ fdrName }}</span>
      </p>
      <p class="h2 text-secondary mx-3 mb-3">
        <!-- <i class="mdi mdi-file-tree"></i> -->
        <!-- <i
          :class="$route.meta.iconClass"
          style="font-size: 22px; color: #072648 !important"
        ></i> -->

        <span style="font-size: 16px" class="font-weight-light">Dossier : </span>
        <span style="font-size: 18px">{{ dossierName }}</span>
      </p>
      <div class="col-12">
        <h4 class="card-title mt-4 mb-1">Liste des Invites :</h4>
      </div>
    </div>
    <div class="col-12">
      <hr />
    </div>

    <div class="col-12">
      <b-table
        :items="items"
        :fields="members.tableFields"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        bordered
        striped
      >
        <template v-slot:cell(avatarUrl)="data">
          <div class="d-flex justify-content-center">
            <b-avatar
              v-b-tooltip.hover="data.item.firstname + ' ' + data.item.lastname"
              style="background-color: gray !important; border-color: gray !important"
              size="2rem"
              :src="'https://dev.api.callstrategy.fr/' + data.item.avatarUrl"
              class="mr-1"
            ></b-avatar>
          </div>
        </template>
        <template v-slot:cell(firstname)="data">
          <div>
            {{ data.value }}
          </div>
        </template>
        <template v-slot:cell(lastname)="data">
          <div>
            {{ data.value }}
          </div>
        </template>
      </b-table>
      <br />
      <b-pagination
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="rows"
        align="right"
        aria-controls="table-list"
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    // items: {
    //   type: Array,
    //   default: () => [],
    // },
    inscription: Object,
    fdrName: {
      type: String,
      default: "",
    },
    dossierName: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    sortBy: "firstname",
    perPage: 5,
    currentPage: 1,
    sortDesc: false,
    sortByFormatted: true,
    filterByFormatted: true,
    sortable: true,
    items: [],
    members: {
      tableItems: [],
      tableFields: [
        { key: "avatarUrl", label: "Image", thStyle: { textAlign: "center !important" } },
        { key: "firstname", label: "Prénom", sortable: true },
        { key: "lastname", label: "Nom", sortable: true },
      ],
    },
  }),

  created() {
    this.$store.dispatch("users/fetchAllResponsables").then(() => {
      this.loadingData = false;
    });
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    rows() {
      return this.items.length;
    },
    responsables() {
      return this.fetchAllResponsables;
    },
  },
  mounted() {
    this.items = this.inscription.invite.users;
  },
  watch: {
    RESPONSABLES: function () {
      this.tableItems = this.RESPONSABLES.map((item) => ({
        ...item,
        actions: this.actions,
      }));
    },
  },
};
</script>

<style></style>
